export const MOCK_GET_SCOPE_PERMISSION: ScopePermsResponse<ScopeAndPmsInfoResponse> =
  {
    code: 0,
    request_summary: {
      request_status: 1,
      request_id: 8362,
    },
    approval_required: {
      add_scope_employees: false,
      disable_scope_sync: true,
      enable_scope_sync: false,
      remove_api_permission: true,
      remove_scope_departments: false,
      remove_scope_employees: true,
    },
    service_scope: {
      is_all: false,
      allowed_departments: [
        {
          department_id: 22235,
          name: 'SeaTalk',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22235,
              name: 'SeaTalk',
            },
          ],
        },
        {
          department_id: 22237,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22237,
              name: 'PFMS',
            },
          ],
        },
        {
          department_id: 30676,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 951,
              name: 'China',
            },
            {
              department_id: 1471,
              name: 'Shopee',
            },
            {
              department_id: 1585,
              name: 'Product & Engineering',
            },
            {
              department_id: 30678,
              name: 'Regional Product',
            },
            {
              department_id: 30683,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 30676,
              name: 'PFMS',
            },
          ],
        },
        {
          department_id: 30680,
          name: 'Design',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 951,
              name: 'China',
            },
            {
              department_id: 1471,
              name: 'Shopee',
            },
            {
              department_id: 1585,
              name: 'Product & Engineering',
            },
            {
              department_id: 30678,
              name: 'Regional Product',
            },
            {
              department_id: 30683,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 30680,
              name: 'Design',
            },
          ],
        },
        {
          department_id: 30794,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 951,
              name: 'China',
            },
            {
              department_id: 1471,
              name: 'Shopee',
            },
            {
              department_id: 1585,
              name: 'Product & Engineering',
            },
            {
              department_id: 30682,
              name: 'Regional Engineering',
            },
            {
              department_id: 30819,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 30794,
              name: 'PFMS',
            },
          ],
        },
        {
          department_id: 30803,
          name: 'SeaTalk',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 951,
              name: 'China',
            },
            {
              department_id: 1471,
              name: 'Shopee',
            },
            {
              department_id: 1585,
              name: 'Product & Engineering',
            },
            {
              department_id: 30682,
              name: 'Regional Engineering',
            },
            {
              department_id: 30819,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 30803,
              name: 'SeaTalk',
            },
          ],
        },
        {
          department_id: 32700,
          name: 'Enterprise Efficiency',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 1235,
              name: 'Indonesia',
            },
            {
              department_id: 20236,
              name: 'Sea Labs',
            },
            {
              department_id: 31760,
              name: 'Product & Engineering',
            },
            {
              department_id: 20223,
              name: 'Regional Product',
            },
            {
              department_id: 32700,
              name: 'Enterprise Efficiency',
            },
          ],
        },
        {
          department_id: 36272,
          name: 'QA',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 1235,
              name: 'Indonesia',
            },
            {
              department_id: 20236,
              name: 'Sea Labs',
            },
            {
              department_id: 31760,
              name: 'Product & Engineering',
            },
            {
              department_id: 20237,
              name: 'Regional Engineering',
            },
            {
              department_id: 36271,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 36272,
              name: 'QA',
            },
          ],
        },
        {
          department_id: 36285,
          name: 'Back End',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 1235,
              name: 'Indonesia',
            },
            {
              department_id: 20236,
              name: 'Sea Labs',
            },
            {
              department_id: 31760,
              name: 'Product & Engineering',
            },
            {
              department_id: 20237,
              name: 'Regional Engineering',
            },
            {
              department_id: 36271,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 36285,
              name: 'Back End',
            },
          ],
        },
        {
          department_id: 36286,
          name: 'Front End',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 1235,
              name: 'Indonesia',
            },
            {
              department_id: 20236,
              name: 'Sea Labs',
            },
            {
              department_id: 31760,
              name: 'Product & Engineering',
            },
            {
              department_id: 20237,
              name: 'Regional Engineering',
            },
            {
              department_id: 36271,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 36286,
              name: 'Front End',
            },
          ],
        },
        {
          department_id: 37288,
          name: 'TPM',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 1235,
              name: 'Indonesia',
            },
            {
              department_id: 20236,
              name: 'Sea Labs',
            },
            {
              department_id: 31760,
              name: 'Product & Engineering',
            },
            {
              department_id: 20237,
              name: 'Regional Engineering',
            },
            {
              department_id: 36271,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 37288,
              name: 'TPM',
            },
          ],
        },
      ],
      allowed_employees: [
        {
          employee_id: 11113,
          name: 'Ahmad Fajar',
          icon_src:
            '/api/app/v2/employee/icon/bf1b49a824a867cb38ce7a4e57c4aac30b03010000001aaa00000000020100cc',
          email: 'ahmad.fajar@shopee.com',
        },
        {
          employee_id: 13304,
          name: 'Muhammad Iqbal (Front End)',
          icon_src:
            '/api/app/v2/employee/icon/ad4e35a449f81bed9bfa23b17ce933c20b0301000001761516685568040100a4',
          email: 'muhammad.iqbal@shopee.com',
        },
        {
          employee_id: 16530,
          name: 'Nathania Wiradharma Surya',
          icon_src:
            '/api/app/v2/employee/icon/e50a49a9d1d8c7c85744478f18ae386a0b0301000000f33f00000000020100db',
          email: 'nathania.wiradharma@shopee.com',
        },
        {
          employee_id: 24179,
          name: 'Debora Apriani',
          icon_src:
            '/api/app/v2/employee/icon/45198fe4c7d033b166788d79120120930b03010000038d670000000002010020',
          email: 'debora.apriani@shopee.com',
        },
        {
          employee_id: 54061,
          name: 'Andi Muhamad Primabudi, S.Kom',
          icon_src:
            '/api/app/v2/employee/icon/933b8d6a740e5c3e2fd6351a6b60ca700b0301000007ed2316690752040100f4',
          email: 'andi.primabudi@shopee.com',
        },
        {
          employee_id: 83274,
          name: 'James William Teo',
          icon_src:
            '/api/app/v2/employee/icon/e45aa2bcd6101b3f1344fe67efd64d720b030100000451b5000000000201004b',
          email: 'james.teo@shopee.com',
        },
        {
          employee_id: 133538,
          name: 'Glenn Lucas Harryara',
          icon_src:
            '/api/app/v2/employee/icon/e61a347fae88f43c38eb7fb0fcc4697e0b0301000000873200000000020100fe',
          email: 'glenn.harryara@shopee.com',
        },
        {
          employee_id: 134434,
          name: 'Bryan Mayson',
          icon_src:
            '/api/app/v2/employee/icon/9525a34e4d26e11d8296cbb00ffa96920b03010000004eaf168229440401006b',
          email: 'bryan.mayson@shopee.com',
        },
        {
          employee_id: 147261,
          name: 'Atika Rizki Nurakhmah',
          icon_src:
            '/api/app/v2/employee/icon/3a6d0699f3bd4e11805ab385d03827120b0301000002d5b900000000020100a7',
          email: 'atika.nurakhmah@shopee.com',
        },
        {
          employee_id: 161600,
          name: 'Barly Djaja',
          icon_src:
            '/api/app/v2/employee/icon/49bc2283f2ca5b7278e5080dd8823b770b03010000007c0c1697587205010036',
          email: 'barly.djaja@shopee.com',
        },
        {
          employee_id: 164927,
          name: 'Fadhil Panigoro Rahardjo',
          icon_src:
            '/api/app/v2/employee/icon/4e22eaeec13928dd1f7880ca0bd47ed40b03010000032e6717019072050100a5',
          email: 'fadhil.panigoro@shopee.com',
        },
        {
          employee_id: 176583,
          name: 'Jessica Andrian',
          icon_src:
            '/api/app/v2/employee/icon/a2f2c4be1594123f91adfccd4c1fafc30b0301000002049d16963776050100c8',
          email: 'jessica.andrian@shopee.com',
        },
        {
          employee_id: 188605,
          name: 'Muhammad Al-Kautsar Maktub',
          icon_src:
            '/api/app/v2/employee/icon/eb8d884245d7445bc6c563f1eca83b480b03010000015a431697587205010012',
          email: 'alkautsar.maktub@shopee.com',
        },
      ],
      request_content: '',
      filters: [],
    },
    data_scope: {
      is_all: true,
      allowed_departments: [],
      allowed_employees: [],
      request_content: '',
      is_sync_enabled: false,
      filters: [],
    },
    api_permissions: {
      permission_groups: [
        {
          permissions: [
            {
              permission_key: 'access.oa.department',
              permission_name: 'Create, Update, and Delete a Department',
              remark:
                'Obtain department list including department ID, name, parent department ID and other related information.',
              status: 2,
              limited_by: 2,
              approval_required: true,
              doc_link: 'https://open.seatalk.io/docs/create-department',
            },
            {
              permission_key: 'access.oa.employee',
              permission_name: 'Onboard/Update/Re-board Employee',
              remark:
                'Onboard/Update/Re-board an employee with detailed information',
              status: 3,
              limited_by: 2,
              approval_required: true,
              doc_link: 'https://open.seatalk.io/docs/onboard-employee',
            },
            {
              permission_key: 'check.employee.existence',
              permission_name: 'Check Employee Existence',
              remark:
                "Verify whether employee exist in an organization or not. If exist, API will return employee's ID.",
              status: 2,
              limited_by: 1,
              approval_required: false,
            },
            {
              permission_key: 'get.bot.subscriber.list',
              permission_name: 'Get Bot Subscriber List',
              remark:
                "Obtain the subscriber list of the app's bot, including the current subscribers.",
              status: 3,
              limited_by: 2,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/Get-Bot-Subscriber-List',
            },
            {
              permission_key: 'get.department.list',
              permission_name: 'Get Department List',
              remark:
                'Obtain department list including department ID, name, parent department ID and other related information.',
              status: 2,
              limited_by: 0,
              approval_required: true,
              doc_link: 'https://open.seatalk.io/docs/get-departments',
            },
            {
              permission_key: 'get.employee.code.by.custom.field',
              permission_name: 'Get Employee Code with Custom Field',
              remark:
                'Get certain employee codes by using custom fields as filters',
              status: 3,
              limited_by: 0,
              approval_required: false,
              doc_link: '',
            },
            {
              permission_key: 'get.employee.code.with.email',
              permission_name: 'Get Employee Code with Email',
              remark:
                "Get an employee's employee_code by providing the employee's email.",
              status: 2,
              limited_by: 2,
              approval_required: false,
              doc_link:
                'https://open.seatalk.io/docs/get-employee-code-with-email',
            },
            {
              permission_key: 'get.employee.language.preference',
              permission_name: 'Get User Language Preference',
              remark: "Obtain user's language preference setting.",
              status: 3,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'get.employee.list',
              permission_name: 'Get Department Employees',
              remark:
                'Obtain employee list under specific departments. The result includes basic information of existing employees.',
              status: 3,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'get.employee.profile',
              permission_name: 'Get Contact Profile',
              remark:
                'Obtain the authorized scope of Contacts data according to the granted permission to the app, including access permission of department lists and user lists.',
              status: 3,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'get.employee.status',
              permission_name: 'Get Employee Status',
              remark:
                "Get employee's working status based on their Join Date and Termination Date",
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'update.oa.employee.avatar',
              permission_name: 'Update Employee Avatar',
              remark: "Update an employee's OA Avatar",
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
          ],
          group_key: 'contacts',
          group_name: 'Contacts',
        },
        {
          permissions: [
            {
              permission_key: 'send.bot.group.chat.message',
              permission_name: 'Send Message to Group Chat',
              remark:
                'Send a message to a group chat which the bot has been added to.',
              status: 0,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'send.bot.single.chat.msg',
              permission_name: 'Send Message to Bot Subscriber',
              remark:
                'Send a message to a subscriber of the bot in the 1-1 chat.',
              status: 0,
              limited_by: 1,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
          ],
          group_key: 'messaging',
          group_name: 'Messaging',
        },
        {
          permissions: [
            {
              permission_key: 'create.approval.item',
              permission_name: 'Create Approval Item',
              remark: 'Create an approval item in Approval Center backend',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'delete.approval.item',
              permission_name: 'Delete Approval Item',
              remark: 'Delete an approval item from Approval Center backend',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'retrieve.approval.item',
              permission_name: 'Get Approval Item',
              remark: 'Get an approval item by its item_id',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'update.approval.item',
              permission_name: 'Update Approval Item',
              remark:
                'Update an existing approval item in Approval Center backend',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
          ],
          group_key: 'approval.center',
          group_name: 'Approval Center API',
        },
        {
          permissions: [
            {
              permission_key: 'get.chat.history',
              permission_name: 'Get Chat History',
              remark: 'Obtain the group chat histories.',
              status: 0,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'get.group.chat.info',
              permission_name: 'Get Group Info',
              remark:
                'Obtain basic information about the group chat, such as the group name, group settings and member list.',
              status: 0,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'get.joined.group.chat.list',
              permission_name: 'Get Joined Group Chat List',
              remark: 'Obtain group chats the bot joined.',
              status: 0,
              limited_by: 2,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'get.message.by.message.id',
              permission_name: 'Get Message by Message ID',
              remark: 'Obtain the message content by the message_id.',
              status: 0,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
          ],
          group_key: 'groupchat',
          group_name: 'Group Chat',
        },
        {
          permissions: [
            {
              permission_key: 'send.template.channel.notice.i18n',
              permission_name: 'Send Service Notice (Internationalised)',
              remark:
                "Send a notification message to an employee through SeaTalk's Application Center service notice channel. With customisable title, description and buttons, service notice messages bridge an app with SeaTalk's IM native notification.",
              status: 0,
              limited_by: 1,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
            {
              permission_key: 'update.app.badge',
              permission_name: 'Set Discover Page Notification Badge',
              remark:
                'Send customised notification event counts and display the notification badge via SeaTalk Discover tab.',
              status: 0,
              limited_by: 1,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
            },
          ],
          group_key: 'appnotifications',
          group_name: 'App Notifications',
        },
      ],
    },
  };

export const MOCK_GET_SCOPE_PERMISSION_EMPTY_DEPT = {
  code: 0,
  request_summary: {
    request_status: 1,
    request_id: 8362,
  },
  service_scope: {
    is_all: false,
    allowed_departments: [],
    allowed_employees: [
      {
        employee_id: 11113,
        name: 'Ahmad Fajar',
        icon_src:
          '/api/app/v2/employee/icon/bf1b49a824a867cb38ce7a4e57c4aac30b03010000001aaa00000000020100cc',
        email: 'ahmad.fajar@shopee.com',
      },
      {
        employee_id: 13304,
        name: 'Muhammad Iqbal (Front End)',
        icon_src:
          '/api/app/v2/employee/icon/ad4e35a449f81bed9bfa23b17ce933c20b0301000001761516685568040100a4',
        email: 'muhammad.iqbal@shopee.com',
      },
      {
        employee_id: 16530,
        name: 'Nathania Wiradharma Surya',
        icon_src:
          '/api/app/v2/employee/icon/e50a49a9d1d8c7c85744478f18ae386a0b0301000000f33f00000000020100db',
        email: 'nathania.wiradharma@shopee.com',
      },
      {
        employee_id: 24179,
        name: 'Debora Apriani',
        icon_src:
          '/api/app/v2/employee/icon/45198fe4c7d033b166788d79120120930b03010000038d670000000002010020',
        email: 'debora.apriani@shopee.com',
      },
      {
        employee_id: 54061,
        name: 'Andi Muhamad Primabudi, S.Kom',
        icon_src:
          '/api/app/v2/employee/icon/933b8d6a740e5c3e2fd6351a6b60ca700b0301000007ed2316690752040100f4',
        email: 'andi.primabudi@shopee.com',
      },
      {
        employee_id: 83274,
        name: 'James William Teo',
        icon_src:
          '/api/app/v2/employee/icon/e45aa2bcd6101b3f1344fe67efd64d720b030100000451b5000000000201004b',
        email: 'james.teo@shopee.com',
      },
      {
        employee_id: 133538,
        name: 'Glenn Lucas Harryara',
        icon_src:
          '/api/app/v2/employee/icon/e61a347fae88f43c38eb7fb0fcc4697e0b0301000000873200000000020100fe',
        email: 'glenn.harryara@shopee.com',
      },
      {
        employee_id: 134434,
        name: 'Bryan Mayson',
        icon_src:
          '/api/app/v2/employee/icon/9525a34e4d26e11d8296cbb00ffa96920b03010000004eaf168229440401006b',
        email: 'bryan.mayson@shopee.com',
      },
      {
        employee_id: 147261,
        name: 'Atika Rizki Nurakhmah',
        icon_src:
          '/api/app/v2/employee/icon/3a6d0699f3bd4e11805ab385d03827120b0301000002d5b900000000020100a7',
        email: 'atika.nurakhmah@shopee.com',
      },
      {
        employee_id: 161600,
        name: 'Barly Djaja',
        icon_src:
          '/api/app/v2/employee/icon/49bc2283f2ca5b7278e5080dd8823b770b03010000007c0c1697587205010036',
        email: 'barly.djaja@shopee.com',
      },
      {
        employee_id: 164927,
        name: 'Fadhil Panigoro Rahardjo',
        icon_src:
          '/api/app/v2/employee/icon/4e22eaeec13928dd1f7880ca0bd47ed40b03010000032e6717019072050100a5',
        email: 'fadhil.panigoro@shopee.com',
      },
      {
        employee_id: 176583,
        name: 'Jessica Andrian',
        icon_src:
          '/api/app/v2/employee/icon/a2f2c4be1594123f91adfccd4c1fafc30b0301000002049d16963776050100c8',
        email: 'jessica.andrian@shopee.com',
      },
      {
        employee_id: 188605,
        name: 'Muhammad Al-Kautsar Maktub',
        icon_src:
          '/api/app/v2/employee/icon/eb8d884245d7445bc6c563f1eca83b480b03010000015a431697587205010012',
        email: 'alkautsar.maktub@shopee.com',
      },
    ],
    request_content: '',
    filters: [],
  },
  data_scope: {
    is_all: false,
    allowed_departments: [],
    allowed_employees: [
      {
        employee_id: 11113,
        name: 'Ahmad Fajar',
        icon_src:
          '/api/app/v2/employee/icon/bf1b49a824a867cb38ce7a4e57c4aac30b03010000001aaa00000000020100cc',
        email: 'ahmad.fajar@shopee.com',
      },
      {
        employee_id: 13304,
        name: 'Muhammad Iqbal (Front End)',
        icon_src:
          '/api/app/v2/employee/icon/ad4e35a449f81bed9bfa23b17ce933c20b0301000001761516685568040100a4',
        email: 'muhammad.iqbal@shopee.com',
      },
      {
        employee_id: 16530,
        name: 'Nathania Wiradharma Surya',
        icon_src:
          '/api/app/v2/employee/icon/e50a49a9d1d8c7c85744478f18ae386a0b0301000000f33f00000000020100db',
        email: 'nathania.wiradharma@shopee.com',
      },
      {
        employee_id: 24179,
        name: 'Debora Apriani',
        icon_src:
          '/api/app/v2/employee/icon/45198fe4c7d033b166788d79120120930b03010000038d670000000002010020',
        email: 'debora.apriani@shopee.com',
      },
      {
        employee_id: 54061,
        name: 'Andi Muhamad Primabudi, S.Kom',
        icon_src:
          '/api/app/v2/employee/icon/933b8d6a740e5c3e2fd6351a6b60ca700b0301000007ed2316690752040100f4',
        email: 'andi.primabudi@shopee.com',
      },
      {
        employee_id: 83274,
        name: 'James William Teo',
        icon_src:
          '/api/app/v2/employee/icon/e45aa2bcd6101b3f1344fe67efd64d720b030100000451b5000000000201004b',
        email: 'james.teo@shopee.com',
      },
      {
        employee_id: 133538,
        name: 'Glenn Lucas Harryara',
        icon_src:
          '/api/app/v2/employee/icon/e61a347fae88f43c38eb7fb0fcc4697e0b0301000000873200000000020100fe',
        email: 'glenn.harryara@shopee.com',
      },
      {
        employee_id: 134434,
        name: 'Bryan Mayson',
        icon_src:
          '/api/app/v2/employee/icon/9525a34e4d26e11d8296cbb00ffa96920b03010000004eaf168229440401006b',
        email: 'bryan.mayson@shopee.com',
      },
      {
        employee_id: 147261,
        name: 'Atika Rizki Nurakhmah',
        icon_src:
          '/api/app/v2/employee/icon/3a6d0699f3bd4e11805ab385d03827120b0301000002d5b900000000020100a7',
        email: 'atika.nurakhmah@shopee.com',
      },
      {
        employee_id: 161600,
        name: 'Barly Djaja',
        icon_src:
          '/api/app/v2/employee/icon/49bc2283f2ca5b7278e5080dd8823b770b03010000007c0c1697587205010036',
        email: 'barly.djaja@shopee.com',
      },
      {
        employee_id: 164927,
        name: 'Fadhil Panigoro Rahardjo',
        icon_src:
          '/api/app/v2/employee/icon/4e22eaeec13928dd1f7880ca0bd47ed40b03010000032e6717019072050100a5',
        email: 'fadhil.panigoro@shopee.com',
      },
      {
        employee_id: 176583,
        name: 'Jessica Andrian',
        icon_src:
          '/api/app/v2/employee/icon/a2f2c4be1594123f91adfccd4c1fafc30b0301000002049d16963776050100c8',
        email: 'jessica.andrian@shopee.com',
      },
      {
        employee_id: 188605,
        name: 'Muhammad Al-Kautsar Maktub',
        icon_src:
          '/api/app/v2/employee/icon/eb8d884245d7445bc6c563f1eca83b480b03010000015a431697587205010012',
        email: 'alkautsar.maktub@shopee.com',
      },
    ],
    request_content: '',
    is_sync_enabled: false,
    filters: [],
  },
  api_permissions: {
    permission_groups: [],
  },
};

export const MOCK_GET_SCOPE_PERMISSION_EMPTY_EMPLOYEE = {
  code: 0,
  request_summary: {
    request_status: 1,
    request_id: 8362,
  },
  service_scope: {
    is_all: false,
    allowed_departments: [
      {
        department_id: 22235,
        name: 'SeaTalk',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 668,
            name: 'Singapore',
          },
          {
            department_id: 708,
            name: 'Shopee',
          },
          {
            department_id: 30929,
            name: 'Product & Engineering',
          },
          {
            department_id: 528,
            name: 'Regional Product',
          },
          {
            department_id: 22238,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 22235,
            name: 'SeaTalk',
          },
        ],
      },
      {
        department_id: 22237,
        name: 'PFMS',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 668,
            name: 'Singapore',
          },
          {
            department_id: 708,
            name: 'Shopee',
          },
          {
            department_id: 30929,
            name: 'Product & Engineering',
          },
          {
            department_id: 528,
            name: 'Regional Product',
          },
          {
            department_id: 22238,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 22237,
            name: 'PFMS',
          },
        ],
      },
      {
        department_id: 30676,
        name: 'PFMS',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30678,
            name: 'Regional Product',
          },
          {
            department_id: 30683,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30676,
            name: 'PFMS',
          },
        ],
      },
      {
        department_id: 30680,
        name: 'Design',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30678,
            name: 'Regional Product',
          },
          {
            department_id: 30683,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30680,
            name: 'Design',
          },
        ],
      },
      {
        department_id: 30794,
        name: 'PFMS',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30682,
            name: 'Regional Engineering',
          },
          {
            department_id: 30819,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30794,
            name: 'PFMS',
          },
        ],
      },
      {
        department_id: 30803,
        name: 'SeaTalk',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30682,
            name: 'Regional Engineering',
          },
          {
            department_id: 30819,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30803,
            name: 'SeaTalk',
          },
        ],
      },
      {
        department_id: 32700,
        name: 'Enterprise Efficiency',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20223,
            name: 'Regional Product',
          },
          {
            department_id: 32700,
            name: 'Enterprise Efficiency',
          },
        ],
      },
      {
        department_id: 36272,
        name: 'QA',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 36272,
            name: 'QA',
          },
        ],
      },
      {
        department_id: 36285,
        name: 'Back End',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 36285,
            name: 'Back End',
          },
        ],
      },
      {
        department_id: 36286,
        name: 'Front End',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 36286,
            name: 'Front End',
          },
        ],
      },
      {
        department_id: 37288,
        name: 'TPM',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 37288,
            name: 'TPM',
          },
        ],
      },
    ],
    allowed_employees: [],
    request_content: '',
    filters: [],
  },
  data_scope: {
    is_all: false,
    allowed_departments: [
      {
        department_id: 22235,
        name: 'SeaTalk',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 668,
            name: 'Singapore',
          },
          {
            department_id: 708,
            name: 'Shopee',
          },
          {
            department_id: 30929,
            name: 'Product & Engineering',
          },
          {
            department_id: 528,
            name: 'Regional Product',
          },
          {
            department_id: 22238,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 22235,
            name: 'SeaTalk',
          },
        ],
      },
      {
        department_id: 22237,
        name: 'PFMS',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 668,
            name: 'Singapore',
          },
          {
            department_id: 708,
            name: 'Shopee',
          },
          {
            department_id: 30929,
            name: 'Product & Engineering',
          },
          {
            department_id: 528,
            name: 'Regional Product',
          },
          {
            department_id: 22238,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 22237,
            name: 'PFMS',
          },
        ],
      },
      {
        department_id: 30676,
        name: 'PFMS',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30678,
            name: 'Regional Product',
          },
          {
            department_id: 30683,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30676,
            name: 'PFMS',
          },
        ],
      },
      {
        department_id: 30680,
        name: 'Design',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30678,
            name: 'Regional Product',
          },
          {
            department_id: 30683,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30680,
            name: 'Design',
          },
        ],
      },
      {
        department_id: 30794,
        name: 'PFMS',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30682,
            name: 'Regional Engineering',
          },
          {
            department_id: 30819,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30794,
            name: 'PFMS',
          },
        ],
      },
      {
        department_id: 30803,
        name: 'SeaTalk',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 951,
            name: 'China',
          },
          {
            department_id: 1471,
            name: 'Shopee',
          },
          {
            department_id: 1585,
            name: 'Product & Engineering',
          },
          {
            department_id: 30682,
            name: 'Regional Engineering',
          },
          {
            department_id: 30819,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 30803,
            name: 'SeaTalk',
          },
        ],
      },
      {
        department_id: 32700,
        name: 'Enterprise Efficiency',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20223,
            name: 'Regional Product',
          },
          {
            department_id: 32700,
            name: 'Enterprise Efficiency',
          },
        ],
      },
      {
        department_id: 36272,
        name: 'QA',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 36272,
            name: 'QA',
          },
        ],
      },
      {
        department_id: 36285,
        name: 'Back End',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 36285,
            name: 'Back End',
          },
        ],
      },
      {
        department_id: 36286,
        name: 'Front End',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 36286,
            name: 'Front End',
          },
        ],
      },
      {
        department_id: 37288,
        name: 'TPM',
        ancestors: [
          {
            department_id: 399,
            name: 'Sea Group Pte Ltd',
          },
          {
            department_id: 1235,
            name: 'Indonesia',
          },
          {
            department_id: 20236,
            name: 'Sea Labs',
          },
          {
            department_id: 31760,
            name: 'Product & Engineering',
          },
          {
            department_id: 20237,
            name: 'Regional Engineering',
          },
          {
            department_id: 36271,
            name: 'Enterprise Efficiency',
          },
          {
            department_id: 37288,
            name: 'TPM',
          },
        ],
      },
    ],
    allowed_employees: [],
    request_content: '',
    is_sync_enabled: false,
    filters: [],
  },
  api_permissions: {
    permission_groups: [],
  },
};

export const MOCK_GET_SCOPE_PERMISSION_AUDIT: ScopePermsResponse<ScopeAndPmsAuditInfoResponse> =
  {
    code: 0,
    v: 2,
    request_summary: {
      request_status: 1,
      ref_no: '20240213-1628-41462',
      rejected_reason: '',
      reviewer: undefined,
    },
    service_scope: {
      is_all: false,
      requested_departments: [
        // {
        //   department_id: 22235,
        //   name: 'SeaTalk',
        //   ancestors: [
        //     {
        //       department_id: 399,
        //       name: 'Sea Group Pte Ltd',
        //     },
        //     {
        //       department_id: 668,
        //       name: 'Singapore',
        //     },
        //     {
        //       department_id: 708,
        //       name: 'Shopee',
        //     },
        //     {
        //       department_id: 30929,
        //       name: 'Product & Engineering',
        //     },
        //     {
        //       department_id: 528,
        //       name: 'Regional Product',
        //     },
        //     {
        //       department_id: 22238,
        //       name: 'Enterprise Efficiency',
        //     },
        //     {
        //       department_id: 22235,
        //       name: 'SeaTalk',
        //     },
        //   ],
        // },
      ],
      allowed_departments: [
        {
          department_id: 22235,
          name: 'SeaTalk',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22235,
              name: 'SeaTalk',
            },
          ],
        },
        {
          department_id: 22237,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22237,
              name: 'PFMS',
            },
          ],
        },
      ],
      allowed_employees: [
        {
          employee_id: 11113,
          name: 'Ahmad Fajar',
          icon_src:
            '/api/app/v2/employee/icon/bf1b49a824a867cb38ce7a4e57c4aac30b03010000001aaa00000000020100cc',
          email: 'ahmad.fajar@shopee.com',
        },
        {
          employee_id: 13304,
          name: 'Muhammad Iqbal (Front End)',
          icon_src:
            '/api/app/v2/employee/icon/ad4e35a449f81bed9bfa23b17ce933c20b0301000001761516685568040100a4',
          email: 'muhammad.iqbal@shopee.com',
        },
        {
          employee_id: 16530,
          name: 'Nathania Wiradharma Surya',
          icon_src:
            '/api/app/v2/employee/icon/e50a49a9d1d8c7c85744478f18ae386a0b0301000000f33f00000000020100db',
          email: 'nathania.wiradharma@shopee.com',
        },
        {
          employee_id: 24179,
          name: 'Debora Apriani',
          icon_src:
            '/api/app/v2/employee/icon/45198fe4c7d033b166788d79120120930b03010000038d670000000002010020',
          email: 'debora.apriani@shopee.com',
        },
        {
          employee_id: 54061,
          name: 'Andi Muhamad Primabudi, S.Kom',
          icon_src:
            '/api/app/v2/employee/icon/933b8d6a740e5c3e2fd6351a6b60ca700b0301000007ed2316690752040100f4',
          email: 'andi.primabudi@shopee.com',
        },
        {
          employee_id: 83274,
          name: 'James William Teo',
          icon_src:
            '/api/app/v2/employee/icon/e45aa2bcd6101b3f1344fe67efd64d720b030100000451b5000000000201004b',
          email: 'james.teo@shopee.com',
        },
        {
          employee_id: 133538,
          name: 'Glenn Lucas Harryara',
          icon_src:
            '/api/app/v2/employee/icon/e61a347fae88f43c38eb7fb0fcc4697e0b0301000000873200000000020100fe',
          email: 'glenn.harryara@shopee.com',
        },
        {
          employee_id: 176583,
          name: 'Jessica Andrian',
          icon_src:
            '/api/app/v2/employee/icon/a2f2c4be1594123f91adfccd4c1fafc30b0301000002049d16963776050100c8',
          email: 'jessica.andrian@shopee.com',
        },
        {
          employee_id: 188605,
          name: 'Muhammad Al-Kautsar Maktub',
          icon_src:
            '/api/app/v2/employee/icon/eb8d884245d7445bc6c563f1eca83b480b03010000015a431697587205010012',
          email: 'alkautsar.maktub@shopee.com',
        },
      ],
      // request_content: 'request new dept',
      request_content: '',
      content_request_status: 0,
      content_rejected_reason: '',
      employees_request_status: 2,
      employees_rejected_reason: '',
      filters: [],
    },
    previous_service_scope: {
      is_all: false,
      allowed_departments: [
        // {
        //   department_id: 22235,
        //   name: 'SeaTalk',
        //   ancestors: [
        //     {
        //       department_id: 399,
        //       name: 'Sea Group Pte Ltd',
        //     },
        //     {
        //       department_id: 668,
        //       name: 'Singapore',
        //     },
        //     {
        //       department_id: 708,
        //       name: 'Shopee',
        //     },
        //     {
        //       department_id: 30929,
        //       name: 'Product & Engineering',
        //     },
        //     {
        //       department_id: 528,
        //       name: 'Regional Product',
        //     },
        //     {
        //       department_id: 22238,
        //       name: 'Enterprise Efficiency',
        //     },
        //     {
        //       department_id: 22235,
        //       name: 'SeaTalk',
        //     },
        //   ],
        // },
        // {
        //   department_id: 22237,
        //   name: 'PFMS',
        //   ancestors: [
        //     {
        //       department_id: 399,
        //       name: 'Sea Group Pte Ltd',
        //     },
        //     {
        //       department_id: 668,
        //       name: 'Singapore',
        //     },
        //     {
        //       department_id: 708,
        //       name: 'Shopee',
        //     },
        //     {
        //       department_id: 30929,
        //       name: 'Product & Engineering',
        //     },
        //     {
        //       department_id: 528,
        //       name: 'Regional Product',
        //     },
        //     {
        //       department_id: 22238,
        //       name: 'Enterprise Efficiency',
        //     },
        //     {
        //       department_id: 22237,
        //       name: 'PFMS',
        //     },
        //   ],
        // },
      ],
      allowed_employees: [
        {
          employee_id: 11113,
          name: 'Ahmad Fajar',
          icon_src:
            '/api/app/v2/employee/icon/bf1b49a824a867cb38ce7a4e57c4aac30b03010000001aaa00000000020100cc',
          email: 'ahmad.fajar@shopee.com',
        },
        {
          employee_id: 13304,
          name: 'Muhammad Iqbal (Front End)',
          icon_src:
            '/api/app/v2/employee/icon/ad4e35a449f81bed9bfa23b17ce933c20b0301000001761516685568040100a4',
          email: 'muhammad.iqbal@shopee.com',
        },
        {
          employee_id: 16530,
          name: 'Nathania Wiradharma Surya',
          icon_src:
            '/api/app/v2/employee/icon/e50a49a9d1d8c7c85744478f18ae386a0b0301000000f33f00000000020100db',
          email: 'nathania.wiradharma@shopee.com',
        },
        {
          employee_id: 24179,
          name: 'Debora Apriani',
          icon_src:
            '/api/app/v2/employee/icon/45198fe4c7d033b166788d79120120930b03010000038d670000000002010020',
          email: 'debora.apriani@shopee.com',
        },
        {
          employee_id: 54061,
          name: 'Andi Muhamad Primabudi, S.Kom',
          icon_src:
            '/api/app/v2/employee/icon/933b8d6a740e5c3e2fd6351a6b60ca700b0301000007ed2316690752040100f4',
          email: 'andi.primabudi@shopee.com',
        },
        {
          employee_id: 83274,
          name: 'James William Teo',
          icon_src:
            '/api/app/v2/employee/icon/e45aa2bcd6101b3f1344fe67efd64d720b030100000451b5000000000201004b',
          email: 'james.teo@shopee.com',
        },
        {
          employee_id: 133538,
          name: 'Glenn Lucas Harryara',
          icon_src:
            '/api/app/v2/employee/icon/e61a347fae88f43c38eb7fb0fcc4697e0b0301000000873200000000020100fe',
          email: 'glenn.harryara@shopee.com',
        },
        {
          employee_id: 134434,
          name: 'Bryan Mayson',
          icon_src:
            '/api/app/v2/employee/icon/9525a34e4d26e11d8296cbb00ffa96920b03010000004eaf168229440401006b',
          email: 'bryan.mayson@shopee.com',
        },
        {
          employee_id: 147261,
          name: 'Atika Rizki Nurakhmah',
          icon_src:
            '/api/app/v2/employee/icon/3a6d0699f3bd4e11805ab385d03827120b0301000002d5b900000000020100a7',
          email: 'atika.nurakhmah@shopee.com',
        },
        {
          employee_id: 161600,
          name: 'Barly Djaja',
          icon_src:
            '/api/app/v2/employee/icon/49bc2283f2ca5b7278e5080dd8823b770b03010000007c0c1697587205010036',
          email: 'barly.djaja@shopee.com',
        },
        {
          employee_id: 164927,
          name: 'Fadhil Panigoro Rahardjo',
          icon_src:
            '/api/app/v2/employee/icon/4e22eaeec13928dd1f7880ca0bd47ed40b03010000032e6717019072050100a5',
          email: 'fadhil.panigoro@shopee.com',
        },
        {
          employee_id: 176583,
          name: 'Jessica Andrian',
          icon_src:
            '/api/app/v2/employee/icon/a2f2c4be1594123f91adfccd4c1fafc30b0301000002049d16963776050100c8',
          email: 'jessica.andrian@shopee.com',
        },
        {
          employee_id: 188605,
          name: 'Muhammad Al-Kautsar Maktub',
          icon_src:
            '/api/app/v2/employee/icon/eb8d884245d7445bc6c563f1eca83b480b03010000015a431697587205010012',
          email: 'alkautsar.maktub@shopee.com',
        },
      ],
      filters: [],
      request_content: '',
    },
    data_scope: {
      is_all: false,
      allowed_departments: [
        {
          department_id: 22237,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22237,
              name: 'PFMS',
            },
          ],
        },
      ],
      requested_departments: [
        {
          department_id: 22237,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22237,
              name: 'PFMS',
            },
          ],
        },
      ],
      allowed_employees: [],
      request_content: '',
      is_sync_enabled: true,
      is_sync_enabled_request_status: 1,
      is_sync_enabled_rejected_reason: '',
      content_request_status: 3,
      content_rejected_reason: 'this is reject reason!',
      employees_request_status: 1,
      employees_rejected_reason: '',
      filters: [],
    },
    previous_data_scope: {
      is_sync_enabled: false,
      is_all: true,
      allowed_departments: [
        {
          department_id: 22235,
          name: 'SeaTalk',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22235,
              name: 'SeaTalk',
            },
          ],
        },
        {
          department_id: 22237,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22237,
              name: 'PFMS',
            },
          ],
        },
      ],
      allowed_employees: [],
      filters: [],
      request_content: '',
    },
    api_permissions: {
      permission_groups: [
        {
          permissions: [
            {
              permission_key: 'access.oa.department',
              permission_name: 'Create, Update, and Delete a Department',
              remark:
                'Obtain department list including department ID, name, parent department ID and other related information.',
              status: 2,
              limited_by: 2,
              approval_required: true,
              doc_link: 'https://open.seatalk.io/docs/create-department',
              request_status: 3,
              rejected_reason:
                'this is rejected message message message message message message message',
            },
            {
              permission_key: 'access.oa.employee',
              permission_name: 'Onboard/Update/Re-board Employee',
              remark:
                'Onboard/Update/Re-board an employee with detailed information',
              status: 3,
              limited_by: 2,
              approval_required: true,
              doc_link: 'https://open.seatalk.io/docs/onboard-employee',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'check.employee.existence',
              permission_name: 'Check Employee Existence',
              remark:
                "Verify whether employee exist in an organization or not. If exist, API will return employee's ID.",
              status: 2,
              limited_by: 1,
              approval_required: false,
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.bot.subscriber.list',
              permission_name: 'Get Bot Subscriber List',
              remark:
                "Obtain the subscriber list of the app's bot, including the current subscribers.",
              status: 3,
              limited_by: 2,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/Get-Bot-Subscriber-List',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.department.list',
              permission_name: 'Get Department List',
              remark:
                'Obtain department list including department ID, name, parent department ID and other related information.',
              status: 0,
              limited_by: 0,
              approval_required: true,
              doc_link: 'https://open.seatalk.io/docs/get-departments',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.employee.code.by.custom.field',
              permission_name: 'Get Employee Code with Custom Field',
              remark:
                'Get certain employee codes by using custom fields as filters',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: '',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.employee.code.with.email',
              permission_name: 'Get Employee Code with Email',
              remark:
                "Get an employee's employee_code by providing the employee's email.",
              status: 0,
              limited_by: 2,
              approval_required: false,
              doc_link:
                'https://open.seatalk.io/docs/get-employee-code-with-email',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.employee.language.preference',
              permission_name: 'Get User Language Preference',
              remark: "Obtain user's language preference setting.",
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.employee.list',
              permission_name: 'Get Department Employees',
              remark:
                'Obtain employee list under specific departments. The result includes basic information of existing employees.',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.employee.profile',
              permission_name: 'Get Contact Profile',
              remark:
                'Obtain the authorized scope of Contacts data according to the granted permission to the app, including access permission of department lists and user lists.',
              status: 1,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.employee.status',
              permission_name: 'Get Employee Status',
              remark:
                "Get employee's working status based on their Join Date and Termination Date",
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'update.oa.employee.avatar',
              permission_name: 'Update Employee Avatar',
              remark: "Update an employee's OA Avatar",
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
          ],
          group_key: 'contacts',
          group_name: 'Contacts',
        },
        {
          permissions: [
            {
              permission_key: 'send.bot.group.chat.message',
              permission_name: 'Send Message to Group Chat',
              remark:
                'Send a message to a group chat which the bot has been added to.',
              status: 2,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 2,
              rejected_reason: '',
            },
            {
              permission_key: 'send.bot.single.chat.msg',
              permission_name: 'Send Message to Bot Subscriber',
              remark:
                'Send a message to a subscriber of the bot in the 1-1 chat.',
              status: 0,
              limited_by: 1,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
          ],
          group_key: 'messaging',
          group_name: 'Messaging',
        },
        {
          permissions: [
            {
              permission_key: 'create.approval.item',
              permission_name: 'Create Approval Item',
              remark: 'Create an approval item in Approval Center backend',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'delete.approval.item',
              permission_name: 'Delete Approval Item',
              remark: 'Delete an approval item from Approval Center backend',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'retrieve.approval.item',
              permission_name: 'Get Approval Item',
              remark: 'Get an approval item by its item_id',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'update.approval.item',
              permission_name: 'Update Approval Item',
              remark:
                'Update an existing approval item in Approval Center backend',
              status: 0,
              limited_by: 0,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 2,
              rejected_reason: '',
            },
          ],
          group_key: 'approval.center',
          group_name: 'Approval Center API',
        },
        {
          permissions: [
            {
              permission_key: 'get.chat.history',
              permission_name: 'Get Chat History',
              remark: 'Obtain the group chat histories.',
              status: 0,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.group.chat.info',
              permission_name: 'Get Group Info',
              remark:
                'Obtain basic information about the group chat, such as the group name, group settings and member list.',
              status: 0,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.joined.group.chat.list',
              permission_name: 'Get Joined Group Chat List',
              remark: 'Obtain group chats the bot joined.',
              status: 0,
              limited_by: 2,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'get.message.by.message.id',
              permission_name: 'Get Message by Message ID',
              remark: 'Obtain the message content by the message_id.',
              status: 3,
              limited_by: 3,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
          ],
          group_key: 'groupchat',
          group_name: 'Group Chat',
        },
        {
          permissions: [
            {
              permission_key: 'send.template.channel.notice.i18n',
              permission_name: 'Send Service Notice (Internationalised)',
              remark:
                "Send a notification message to an employee through SeaTalk's Application Center service notice channel. With customisable title, description and buttons, service notice messages bridge an app with SeaTalk's IM native notification.",
              status: 2,
              limited_by: 1,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
            {
              permission_key: 'update.app.badge',
              permission_name: 'Set Discover Page Notification Badge',
              remark:
                'Send customised notification event counts and display the notification badge via SeaTalk Discover tab.',
              status: 3,
              limited_by: 1,
              approval_required: false,
              doc_link: 'https://open.seatalk.io/docs/get-user-lang-pref',
              request_status: 1,
              rejected_reason: '',
            },
          ],
          group_key: 'appnotifications',
          group_name: 'App Notifications',
        },
      ],
    },
  };

export const getScopePermission = (_: any, res: any, ctx: any) =>
  res(ctx.status(200), ctx.json(MOCK_GET_SCOPE_PERMISSION));

export const getScopePermissionAudit = (_: any, res: any, ctx: any) =>
  res(ctx.status(200), ctx.json(MOCK_GET_SCOPE_PERMISSION_AUDIT));
