import React, { useState } from 'react';

interface OrganizationsContextInterface {
  organizations: Organization[];
  setOrganizations: React.Dispatch<React.SetStateAction<Organization[]>>;
  isLoadingOrganizations: boolean;
  setIsLoadingOrganizations: (isLoadingOrganizations: boolean) => void;
}

export const OrganizationsContext =
  React.createContext<OrganizationsContextInterface>(
    {} as OrganizationsContextInterface
  );

const OrganizationsProvider: React.FC = ({ children }) => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoadingOrganizations, setIsLoadingOrganizations] =
    useState<boolean>(true);

  return (
    <OrganizationsContext.Provider
      value={{
        organizations,
        setOrganizations,
        isLoadingOrganizations,
        setIsLoadingOrganizations,
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
};

export default OrganizationsProvider;
