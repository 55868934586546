import { init } from '@sentry/react';

import { IS_LIVE } from './utils/constants';

try {
  if (process.env.NODE_ENV === 'production') {
    init({
      dsn: IS_LIVE
        ? 'https://3618fb3a23634732b87d7e4f0edd1f3b@o506518.ingest.sentry.io/5873389'
        : 'https://1fac20aec0b14c51b364de79b1bb1d35@o506518.ingest.sentry.io/5873391',
      tracesSampleRate: 1.0,
      release: process.env.BUILD_RELEASE,
      environment: process.env.BUILD_ENV,
    });
  }
} catch (error) {
  /* eslint-disable no-console */
  console.error(error);
}
