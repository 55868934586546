import React, { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Menu } from 'antd';
import Box from '@seaweb/coral/components/Box';

import { ActiveOrganizationContext } from 'providers';

import Org from './Org';
import User from './User';

import styles from './Nav.module.less';
import MenuItem from 'antd/lib/menu/MenuItem';

interface MenuEntry {
  key: string;
  value: string;
  path: string;
}
// menu Tabs
const menuTabs: MenuEntry[] = [
  {
    key: 'company',
    value: 'Apps',
    path: '/company',
  },
  {
    key: 'docs',
    value: 'Documents',
    path: '/docs',
  },
  {
    key: 'showcases',
    value: 'Showcases',
    path: 'https://sites.google.com/shopee.com/seatalk-open-platform',
  },
];

const defaultPage = 'company';

const Nav: React.FC = () => {
  const match = useRouteMatch<{ page: string }>('/:page');
  let page = match?.params.page || defaultPage;
  if (!menuTabs.find((item) => item.key === page)) {
    page = defaultPage;
  }

  const { activeOrganization } = useContext(ActiveOrganizationContext);

  const renderMenu = (menu: MenuEntry) => {
    if (menu.value === 'Showcases') {
      if (activeOrganization.is_sea) {
        return (
          <MenuItem key={menu.key}>
            <a target="_blank" href={menu.path} className={styles.link}>
              {menu.value}
            </a>
          </MenuItem>
        );
      }
      return;
    }
    return (
      <Menu.Item
        key={menu.key}
        disabled={!activeOrganization.id && menu.key !== 'docs'}
      >
        <Link className={styles.link} to={menu.path}>
          {menu.value}
        </Link>
      </Menu.Item>
    );
  };

  return (
    <Box>
      <Org />
      <Menu
        className={styles.menu}
        theme="dark"
        mode="horizontal"
        selectedKeys={[page]}
      >
        {menuTabs.map(renderMenu)}
      </Menu>
      <User />
    </Box>
  );
};

export default Nav;
