import { getAppProfileResponse } from 'pages/apps-tabs/apps/types';

const appInfoImmediateApproval: getAppProfileResponse = {
  request_summary: { request_status: 7, request_id: 0 },
  approval_required: {
    basic_info: false,
  },
  app_info: {
    description: 'to test add dept in preset!',
    icon: 'fb2334f13891fa331357de089022d5bc0b030100000008e617140032080130f0',
    icon_src: '/api/app/v2/app/icon/uss-AnQx38sArE=WUDwRWQIAJw',
    uss_icon: 'AnQx38sArE=WUDwRWQIAJw',
    app_id: 13178,
    name: 'test bot add dept 1[true()]',
  },
  app_owner: {
    owner: {
      employee_id: 906209,
      name: 'Jessica',
      icon_src:
        '/api/app/v2/employee/icon/ae226e5c0ed1561af8a5c12d8d905a950b0301000000056e1681776004010082',
      email: 'jessica.andrian@shopee.com',
    },
    is_owned_by_me: true,
  },
  credentials: {
    display_id: 'NTE0MTQ5ODk4MTU2',
    secret: 'sGzVAA8VOT25klSBYvFMf_rKrRRoPv0K',
  },
  code: 0,
  app_profile: {
    app_status: 1,
    rn_enabled: true,
    release_enabled: true,
    has_released: false,
    bot_seatalk_id: '9338895882',
  },
  web: { mobile_url: '', desktop_url: '', updated: false },
  capabilities: { bot: 1, app: 0, login_with_seatalk: 0 },
};

const editBasicInfoSuccess = {
  code: 0,
};

export const getAppInfoNotRequireApproval = (_: any, res: any, ctx: any) =>
  res(ctx.status(200), ctx.json(appInfoImmediateApproval));
export const postBasicInfoSuccess = (_: any, res: any, ctx: any) =>
  res(ctx.status(200), ctx.json(editBasicInfoSuccess));
