import { LoginSeatalkConfigResponse } from 'pages/apps-tabs/apps-capability/types/loginWithSeatalk';

const configNotRequiredApproval: LoginSeatalkConfigResponse = {
  code: 0,
  request_summary: { request_status: 0, request_id: 17613 },
  onetap: 1,
  approval_required: {
    enable_onetap: false,
  },
};

export const getAppInfoNotRequireApproval = (_: any, res: any, ctx: any) =>
  res(ctx.status(200), ctx.json(configNotRequiredApproval));
