import React from 'react';
import classnames from 'classnames';
import { Body } from '@seaweb/coral/components/Typography';
import * as Sentry from '@sentry/react';

import { HttpRequestError } from 'utils/enums';

import styles from './ErrorScreen.module.less';

const getDescription = (errorCode: number) => {
  switch (errorCode) {
    case HttpRequestError.Error400:
      try {
        Sentry.captureException(new Error(`Page 404: ${window.location.href}`));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('404 error:', error);
      }
      return "The page you're looking for cannot be accessed.";
    case HttpRequestError.Error500:
      try {
        Sentry.captureException(new Error(`Page 500: ${window.location.href}`));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('500 error:', error);
      }
      return "An error ocurred and your request couldn't be completed.";
    default:
      return '';
  }
};
const ErrorScreen = (errorCode: number) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <div className={classnames(styles[`illustration-${errorCode}`])}></div>
      <Body>{getDescription(errorCode)}</Body>
    </div>
  </div>
);

export default ErrorScreen;
