import React from 'react';
import createIcon from '@seaweb/coral/components/icons/createIcon';

const Icon = createIcon(
  'Cross',
  ({ color }) => (
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6261 4.31249C15.9385 4.62491 15.9385 5.13144 15.6261 5.44386L5.44375 15.6262C5.13133 15.9386 4.6248 15.9386 4.31238 15.6262C3.99996 15.3138 3.99996 14.8072 4.31238 14.4948L14.4947 4.31249C14.8071 4.00007 15.3137 4.00007 15.6261 4.31249Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.34679 4.34669C4.65921 4.03427 5.16574 4.03427 5.47816 4.34669L15.6605 14.529C15.9729 14.8414 15.9729 15.348 15.6605 15.6604C15.3481 15.9728 14.8415 15.9728 14.5291 15.6604L4.34679 5.47806C4.03437 5.16564 4.03437 4.65911 4.34679 4.34669Z"
        fill={color}
      />
    </g>
  ),
  {
    viewBox: '0 0 20 20',
    size: 20,
  }
);

export default Icon;
