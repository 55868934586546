import React, { memo } from 'react';
import CoralPagination, {
  PaginationList,
} from '@seaweb/coral/components/Pagination';
import { Select } from 'antd';

import styles from './Pagination.module.less';

const { Option } = Select;

export enum PaginationKey {
  page = 'page',
  pageSize = 'pageSize',
}

interface Props {
  paginator: Paginator;
  onChange?: (key: PaginationKey, value: number) => void;
  itemProps?: { className?: string; itemSize?: number };
}

const Pagination: React.FC<Props> = ({ paginator, onChange, itemProps }) => {
  if (paginator.total_entries === 0 || paginator.total_pages === 0) {
    return null;
  }

  const page = paginator?.page || 1;
  const totalApps = paginator?.total_entries || 0;
  const totalText = `Total: ${totalApps} App${totalApps > 1 && 's'}`;

  return (
    <div className={styles.paginator}>
      <div className={styles.totalPages}>
        {/* Total: {paginator.total_pages} Pages */}
        {totalText}
      </div>
      <CoralPagination
        current={page}
        onChange={(pageNum: number): void => {
          onChange && onChange(PaginationKey.page, pageNum);
        }}
        totalRow={paginator.total_entries || 0}
        pageSize={paginator.per_page}
      >
        <PaginationList {...itemProps} />
      </CoralPagination>
      <div className={styles.pageSize}>
        <Select
          dropdownMatchSelectWidth={true}
          value={paginator.per_page}
          onChange={(value: number): void => {
            onChange && onChange(PaginationKey.pageSize, value);
          }}
        >
          <Option value={10}>10 / Page</Option>
          <Option value={20}>20 / Page</Option>
          <Option value={50}>50 / Page</Option>
          <Option value={100}>100 / Page</Option>
        </Select>
      </div>
    </div>
  );
};

export default memo(Pagination);
