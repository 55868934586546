import React, { useContext, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Button, { ButtonVariants } from '@seaweb/coral/components/Button';
import Dialog, {
  DialogTitle,
  DialogClose,
  DialogContent,
  DialogActions,
} from '@seaweb/coral/components/Dialog';
import { ColorTypes } from '@seaweb/coral/components/ThemeProvider';

import { ActiveOrganizationContext } from 'providers';
import locale from 'common/locale';
import { useHttpRequest } from 'hooks/useHttpRequest';
import CreateAppForm, {
  CreateAppFormInputs,
} from 'components/app-info-form/CreateAppInfoForm';
import { RequestStatus } from 'utils/enums';
import { ResponseCode } from 'common/api';

import { createApp } from './api';

import styles from './AppsList.module.less';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmitted: (app_id: ID) => void;
}

const CreateApp: React.FC<Props> = (props) => {
  const { activeOrganization } = useContext(ActiveOrganizationContext);
  const { request, requestStatus: requestCreateAppStatus } = useHttpRequest();
  const { isOpen, onSubmitted, onClose } = props;

  const formHook = useForm<CreateAppFormInputs>({
    mode: 'onChange',
  });
  const { formState, reset, getValues, setError } = formHook;

  const submitHandler = async () => {
    const formValues = getValues();
    try {
      const response = await request(
        createApp({
          companyId: activeOrganization.id,
          name: formValues.name,
          description: formValues.description,
          icon: formValues.avatar.icon_name,
          uss_icon: formValues?.avatar.uss_icon || '',
        }),
        false
      );
      if (response.code !== 0) {
        throw response;
      }
      onSubmitted(response.app_id);
      reset();
    } catch (error) {
      switch (error.code) {
        case ResponseCode.APP_NAME_DUPLICATE.code:
          setError('name', {
            type: 'manual',
            message: locale('validation_error_name_duplicate'),
          });
          break;

        default:
          break;
      }
    }
  };

  const closeHandler = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  return (
    <Dialog isOpen={isOpen} className={styles.createAppModal}>
      <DialogTitle>
        {locale('app_list_create_app')}
        <DialogClose onClick={closeHandler} />
      </DialogTitle>
      <DialogContent>
        <CreateAppForm
          labelWidthPercent={20}
          fieldWidthPercent={80}
          activeOrganization={activeOrganization}
          formHook={formHook}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeHandler}
          disabled={requestCreateAppStatus === RequestStatus.REQUESTING}
          variant={ButtonVariants.Outlined}
        >
          {locale('action_cancel')}
        </Button>
        <Button
          onClick={submitHandler}
          colorType={ColorTypes.Primary}
          disabled={
            !formState.isValid ||
            requestCreateAppStatus === RequestStatus.REQUESTING
          }
        >
          {locale('action_create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateApp;
