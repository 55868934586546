import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Menu, Dropdown, Space } from 'antd';
import Avatar from '@seaweb/coral/components/Avatar';

import locale from 'common/locale';
import { CurrentUserContext } from 'providers';
import { useHttpRequest } from 'hooks/useHttpRequest';
import { HttpRequestMethod } from 'utils/enums';
import { LOGIN_COOKIE } from 'utils/storage';

import styles from './User.module.less';

const User: React.FC = () => {
  const history = useHistory();
  const [, , removeCookie] = useCookies([LOGIN_COOKIE]);
  const { currentUser: user } = useContext(CurrentUserContext);
  const { request } = useHttpRequest();

  const onClickLogout = async () => {
    await request({
      url: 'auth/v2/logout',
      method: HttpRequestMethod.Post,
    });

    removeCookie(LOGIN_COOKIE, { path: '/' });
    window.localStorage.clear();
    history.push('/login');
  };

  return (
    <Dropdown
      className={styles.dropdown}
      overlay={
        <Menu>
          <Menu.Item key="logout" onClick={onClickLogout}>
            {locale('nav_logout')}
          </Menu.Item>
        </Menu>
      }
      trigger={['click']}
    >
      <Space direction="horizontal" align="center" size={12}>
        <Avatar
          size={32}
          src={user?.icon_src || ''}
          fallback={`${process.env.PUBLIC_URL}/images/user.png`}
        />
        <span className={styles.name}>{user?.name}</span>
      </Space>
    </Dropdown>
  );
};

export default User;
