import React from 'react';
import styled from 'styled-components';
import IconButton from '@seaweb/coral/components/IconButton';

import CrossIcon from 'components/icons/Cross';
import locale from 'common/locale';
import HowToScanImageSrc from 'assets/images/howtoscan.png';

const HowToScanWrapper = styled.div<{ isClicked: boolean }>`
  width: 386px;
  height: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  color: rgb(32, 35, 37);
  box-shadow: rgba(94, 94, 117, 0.15) 0px 4px 14px 0px;
  padding: 0 40px;
  position: absolute;
  top: 0;
  transform: ${(props) =>
    props.isClicked ? 'translateX(0)' : 'translateX(40px)'};
  opacity: ${(props) => (props.isClicked ? 1 : 0)};
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  border-radius: 7px;

  .howto-description {
    margin-top: 20px;
    margin-bottom: 26px;
    text-align: center;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 20px;
    right: -7px;
    transform: translateY(-50%);
    width: 0px;
    height: 0px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid rgb(255, 255, 255);
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
`;

const HowToScanImage = styled.img`
  max-width: 360px;
`;

interface IHowToScanProps {
  isShown: boolean;
  hideHowToScan: () => void;
}

const HowToScan = ({ isShown, hideHowToScan }: IHowToScanProps) => (
  <HowToScanWrapper isClicked={isShown}>
    <StyledIconButton onClick={hideHowToScan}>
      <CrossIcon color="#4178ac" />
    </StyledIconButton>
    <HowToScanImage src={HowToScanImageSrc} alt="how to scan" />
    <p className="howto-description">{locale('login_how_to_scan_desc')}</p>
  </HowToScanWrapper>
);

export default HowToScan;
