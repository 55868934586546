import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { useHttpRequest } from 'hooks/useHttpRequest';
import { getAvatarUrl } from 'utils/file';
import {
  ESelectedLoginMethod,
  LOGIN_COOKIE,
  SHOULD_TRACK_SUCCESS_LOGIN_SESSION,
} from 'utils/storage';
import {
  sendTrackingEvent,
  TRACK_GOOGLE_LOGIN_SUCCESS,
  TRACK_QR_LOGIN_SUCCESS,
} from 'utils/tracking';

import * as api from './api';
import { OrganizationsContext } from './OrganizationsProvider';

interface CurrentUserContextInterface {
  currentUser: CurrentUser;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser>>;
}

export const CurrentUserContext =
  React.createContext<CurrentUserContextInterface>(
    {} as CurrentUserContextInterface
  );

const CurrentUserProvider: React.FC = ({ children }) => {
  const [cookies, , removeCookie] = useCookies([LOGIN_COOKIE]);
  const { request } = useHttpRequest();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState<CurrentUser>(
    {} as CurrentUser
  );
  const { setOrganizations, setIsLoadingOrganizations } =
    useContext(OrganizationsContext);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingOrganizations(true);
        const res = await request(api.getAuthMe(), false);
        const { icon_src, companies } = res || {};

        setCurrentUser({ ...res, icon_src: getAvatarUrl(icon_src) });
        setOrganizations(companies);

        if (
          sessionStorage.getItem(SHOULD_TRACK_SUCCESS_LOGIN_SESSION) ===
          ESelectedLoginMethod.QR
        ) {
          sendTrackingEvent({
            name: TRACK_QR_LOGIN_SUCCESS,
            parameters: { seatalk_id: res.seatalk_id },
          });
        } else if (
          sessionStorage.getItem(SHOULD_TRACK_SUCCESS_LOGIN_SESSION) ===
          ESelectedLoginMethod.Google
        ) {
          sendTrackingEvent({
            name: TRACK_GOOGLE_LOGIN_SUCCESS,
            parameters: { seatalk_id: res.seatalk_id },
          });
        }

        sessionStorage.removeItem(SHOULD_TRACK_SUCCESS_LOGIN_SESSION);
      } catch (error) {
        if (error.message === 'Unauthorized' && cookies[LOGIN_COOKIE]) {
          removeCookie(LOGIN_COOKIE, { path: '/' });
          history.push('/login');
        }
      } finally {
        setIsLoadingOrganizations(false);
      }
    })();
  }, [
    setCurrentUser,
    setIsLoadingOrganizations,
    setOrganizations,
    history,
    request,
    cookies,
    removeCookie,
  ]);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
