import { MAX_AVATAR_SIZE } from './constants';

export function getAvatarUrl(src: string | undefined) {
  if (!src) {
    return '';
  }
  return `${src}_${MAX_AVATAR_SIZE}`;
}

export function checkUploadImageType(file: File): boolean {
  const fileNameArr = file.name.split('.');
  const fileSuffix = fileNameArr[fileNameArr.length - 1];
  // acceptable upload image types
  return (
    !!fileSuffix && ['png', 'jpg', 'jpeg'].includes(fileSuffix.toLowerCase())
  );
}
