import locale from 'common/locale';
import { ResponseCode } from 'common/api';

export function formatReponseValidationErrors(errors: ValidationError[]) {
  return errors.map(({ field, reason }) =>
    // parse validation errors from server to text
    ({
      field,
      error: {
        // react-hook form need these fields.
        type: 'server',
        message: locale(`validation_error_${field}_${reason}`),
      },
    })
  );
}

export function sortByKey(array: any[], key: string, asc = true) {
  return array.sort((a, b) => {
    const x = a[key];
    const y = b[key];

    // eslint-disable-next-line no-nested-ternary
    return (x < y ? -1 : x > y ? 1 : 0) * (asc ? 1 : -1);
  });
}

export function getErrorMsg(code: number | string): string {
  if (typeof code === 'string') {
    return ResponseCode[code]?.desc || '';
  }
  const errorKey = Object.keys(ResponseCode).find(
    (key) => ResponseCode[key].code === code
  );
  return (errorKey && ResponseCode[errorKey]?.desc) || 'Unknown Error';
}
