import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './Spinner.module.less';

interface Props {
  wrapperHeight?: number | string;
}

const Spinner: React.FC<Props> = ({ wrapperHeight }) => {
  const SpinEle = (
    <Spin className={styles.spinner} indicator={<LoadingOutlined spin />} />
  );
  return wrapperHeight ? (
    <div className={styles.wrapper} style={{ height: wrapperHeight }}>
      {SpinEle}
    </div>
  ) : (
    SpinEle
  );
};

export default Spinner;
