import { HttpRequestMethod } from 'utils/enums';

interface UploadIconParams {
  companyId: ID;
  file: File;
}

export function uploadIcon({ companyId, file }: UploadIconParams): RequestType {
  const formData = new FormData();
  formData.append('company_id', String(companyId));
  formData.append('file', file);
  return {
    url: 'app/v2/app/icon',
    method: HttpRequestMethod.Post,
    options: {
      body: formData,
    },
  };
}
