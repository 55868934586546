import React from 'react';
import styled from 'styled-components';
import { Modal, Button as AntdButton } from 'antd';

import locale from 'common/locale';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 14px 24px;
    background: #ffffff;
    border-bottom: 1px solid #e8e8e8;
  }
`;

const ModalTitle = styled.div`
  text-align: center;
`;

const ModalDescription = styled.p`
  margin-bottom: 30px;
`;

const StyledAntdButton = styled(AntdButton)`
  border: 1px solid #e1e1e7;
`;

export enum ELoginErrorType {
  UnexpectedError = '1',
  DeactivatedAccount = '2',
  InexistentAccount = '3',
}

interface ILoginFailedModalProps {
  errorType: TLoginErrorTypeKey;
  visible: boolean;
  closeModal: () => void;
}

// Unexpected Error (1) will be handled using regular toast
export type TLoginErrorTypeKey =
  | ELoginErrorType.DeactivatedAccount
  | ELoginErrorType.InexistentAccount;
const LoginErrorMessage: Record<TLoginErrorTypeKey, string> = {
  [ELoginErrorType.DeactivatedAccount]: locale(
    'login_google_account_unavailable_modal_desc'
  ),
  [ELoginErrorType.InexistentAccount]: locale(
    'login_google_failed_modal_description'
  ),
};

const LoginFailedModal = ({
  errorType,
  visible,
  closeModal,
}: ILoginFailedModalProps) => (
  <StyledModal
    title={<ModalTitle>{locale('login_google_failed_modal_title')}</ModalTitle>}
    centered
    visible={visible}
    footer={null}
    bodyStyle={{
      background: '#F8F8FA',
      padding: '30px 20px 20px',
      textAlign: 'center',
    }}
    maskClosable={false}
    width={360}
    onCancel={closeModal}
  >
    <ModalDescription>{LoginErrorMessage[errorType]}</ModalDescription>
    <StyledAntdButton block onClick={closeModal}>
      {locale('login_google_failed_modal_btn')}
    </StyledAntdButton>
  </StyledModal>
);

export default LoginFailedModal;
