import locale from 'common/locale';

import { IS_LIVE } from './constants';

interface StringMap {
  [key: string]: string;
}
export enum AppStatus {
  NotReleased = 0,
  Online = 1,
  Maintenance = 2,
  Offline = 3,
}

export enum UserRole {
  Developer = 1,
  ReleaseManager = 2,
}

export enum AppStatusType {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

export enum AppType {
  DISABLED = -1,
  RN = 1,
  WEB = 2,
  BOT = 3,
}
export const AppTypeText: StringMap = {
  [AppType.DISABLED]: 'Disabled',
  [AppType.RN]: 'RN App',
  [AppType.WEB]: 'Web App',
  [AppType.BOT]: 'Bot',
};

export enum DesktopType {
  DISABLED = -1,
  WEB = 2,
}
export const DesktopTypeText: StringMap = {
  [DesktopType.DISABLED]: 'Disabled',
  [DesktopType.WEB]: 'Web App',
};

// export enum BotType {
//   DISABLED = -1,
//   ENABLED = 1,
// }

// export const BotTypeText: StringMap = {
//   [BotType.DISABLED]: 'Disabled',
//   [BotType.ENABLED]: 'Enabled',
// };

export enum HostOption {
  NO_HOST = 0,
  SELF_HOST = 1,
  HOSTED_BY_SEATALK = 2,
}

export enum BundleState {
  DEVELOPMENT = 0,
  PRODUCTION = 1,
  HISTORY = 2,
}

export enum AppVersionStatus {
  Draft = 0,
  UnderReview = 1,
  Rejected = 2,
  Released = 3,
}

export enum VersionNoValidationResult {
  Valid = 0,
  Invalid = 1,
  LTEPrevious = 2,
  LTLowerBound = 3,
  GTUpperBound = 4,
}

export enum HttpRequestError {
  NoError = 0,
  Error400 = 400,
  Error500 = 500,
}

export enum HttpRequestMethod {
  Get = 'get',
  Post = 'post',
}

export enum AvailabilityDialogScope {
  All = 'all',
  Some = 'some',
}

export enum UploadStatus {
  NOT_UPLOAD = 0,
  UPLOADING = 1,
  UPLOADED = 2, // file sent to server
  FINISH = 3, // server responses
}

export enum RequestStatus {
  IDLE = 0,
  REQUESTING = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export enum UploadAppBundleValidationCode {
  SUCCESS = 0,
  CANT_PARSED = 3002001,
  IOS_MD5_FAILED = 3002002,
  ANDROID_MD5_FAILED = 3002003,
  APP_ID_NOT_CORRECT = 3002004,
  VERSION_TAKEN = 3002005,
  NO_MANIFEST = 3002006,
  INVALID_MANIFEST = 3002007,
  NO_ZIP_FILES = 3002008,
  FILE_SIZE_EXCEED = 3002009,
  MORE_THAN_3_FILES = 3002010,
  INVALID_VERSION = 3002011,
}

export enum CreateAppBundleValidationCode {
  SUCCESS = 0,
  NOT_IN_DEVELOP = 3002012,
  CLIENT_ID_NOT_MATCHING = 3002013,
  BUNDLE_VERSION_EXIST = 3002014,
  MAX_REMARK = 3002015,
}

export enum formCreateAppValidationError {
  NAME_DUPLICATE = 'duplicate',
  FIELD_MISSING = 'missing',
  MAX_LENGTH = 'max_length',
  FILE_TOO_LARGE = 'too_large',
}

/**
 * @deprecated
 */
export enum ActionCode {
  NO_CHANGE = 0,
  ADDED = 1,
  DELETED = 2,
  // BE: 以前用到的只有 0 1 2； 没有使用 3
  UPDATED = 3,
}

export enum PermissionStatusCode {
  DELETED = 2,
  ADDED = 3,
  HIDDEN = 4,
}

export enum EventCallbackType {
  APP_ONLINE = 'app_online',
  APP_OFFLINE = 'app_offline',
}

export enum EventCallbackENV {
  PROD = 'production',
  TEST = 'test',
}

const IS_NOT_REPORTING_MANAGER = 0;
const IS_REPORTING_MANAGER = 1;
export const REPORTING_MANAGER_STATUS = {
  [IS_REPORTING_MANAGER]: locale(
    'components.EmployeeFilter.selectReportingManagerStatus.isReportingManager'
  ),
  [IS_NOT_REPORTING_MANAGER]: locale(
    'components.EmployeeFilter.selectReportingManagerStatus.isNotReportingManager'
  ),
};

export const EMPLOYMENT_TYPE_DISPLAY = [
  'Blank',
  'Full Time',
  'Part Time',
  'Shift Worker',
  'Contractor',
  'Internship',
  'Other',
];

export const FEATURE_TOGGLE = {
  CAN_SEE_CREATE_TEST_ORG_BUTTON: IS_LIVE
    ? '1501868878500728832'
    : '1499288808770768896',
};

/** 区分进入APP详情页的来源 */
export enum AppDetailPageSourceType {
  /** 创建app成功后跳转详情 */
  CREATE_APP_SUCCESS,
}

export enum PageType {
  Edit,
  Detail,
}

export enum APIVersion {
  V1 = 1,
  V2 = 2,
}

export enum GoogleLoginSearchParams {
  Code = 'code',
  Error = 'error',
  State = 'state',
  CompleteError = 'login_error',
}
