import React, { useCallback } from 'react';
import {
  usePusherContext,
  PusherPositions,
} from '@seaweb/coral/components/Pusher';
import Toast, { ToastTypes } from '@seaweb/coral/components/Toast';

export default function usePushToast() {
  const { push } = usePusherContext();
  return useCallback(
    (
      type: ToastTypes,
      message: string,
      hideIcon = false,
      position = PusherPositions.TopCenter
    ) => {
      const toast = hideIcon ? (
        <Toast icon={null}>{message}</Toast>
      ) : (
        <Toast type={type}>{message}</Toast>
      );
      push(toast, { position });
    },
    [push]
  );
}
