import React, { useState } from 'react';
import { BrowserRouter, BrowserRouterProps } from 'react-router-dom';
import Button, { ButtonVariants } from '@seaweb/coral/components/Button';
import { ColorTypes } from '@seaweb/coral/components/ThemeProvider';
import { Body } from '@seaweb/coral/components/Typography';
import Dialog, {
  DialogTitle,
  DialogClose,
  DialogContent,
  DialogActions,
} from '@seaweb/coral/components/Dialog';

const BrowserRouterUserConfirm: React.FC<BrowserRouterProps> = ({
  children,
}) => {
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmCallback, setConfirmCallback] = useState<(ok: boolean) => void>(
    () => {
      /* do nothing */
    }
  );
  const getUserConfirmation = (
    message: string,
    callback: (ok: boolean) => void
  ) => {
    setConfirmMessage(message);
    setConfirmCallback(() => callback);
  };

  return (
    <BrowserRouter getUserConfirmation={getUserConfirmation}>
      {children}
      <Dialog
        style={{ width: 480 }}
        isOpen={!!confirmMessage}
        onClose={() => {
          setConfirmMessage('');
        }}
      >
        <DialogTitle>
          Leave Page
          <DialogClose />
        </DialogTitle>
        <DialogContent>
          <Body>{confirmMessage}</Body>
        </DialogContent>
        <DialogActions>
          <Button
            variant={ButtonVariants.Outlined}
            colorType={ColorTypes.Secondary}
            onClick={() => {
              setConfirmMessage('');
              confirmCallback(true);
            }}
          >
            Leave
          </Button>
          <Button
            onClick={() => {
              setConfirmMessage('');
              confirmCallback(false);
            }}
          >
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </BrowserRouter>
  );
};

export default BrowserRouterUserConfirm;
