import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown, Space, Avatar, Row, Col } from 'antd';
import DropdownIcon from '@seaweb/coral/components/icons/Dropdown';
import SelectedIcon from '@seaweb/coral/components/icons/Selected';
import { palette } from '@seaweb/coral/components/ThemeProvider';

// eslint-disable-next-line import/no-absolute-path
import logoImg from '/assets/images/icon_login_seatalk_en.png';

import qs from 'qs';

import { OrganizationsContext, ActiveOrganizationContext } from 'providers';

import styles from './Org.module.less';

const Org: React.FC = () => {
  const history = useHistory();

  const { organizations, isLoadingOrganizations } =
    useContext(OrganizationsContext);
  const { activeOrganization, setActiveOrganization } = useContext(
    ActiveOrganizationContext
  );

  if (!activeOrganization.id && isLoadingOrganizations) {
    return null;
  }

  if (!isLoadingOrganizations && organizations.length === 0) {
    return (
      <div>
        <img className={styles.logo} src={logoImg} alt="" />
      </div>
    );
  }

  return (
    <Dropdown
      className={styles.org}
      overlay={
        organizations.length > 1 ? (
          <Menu
            theme="dark"
            onClick={({ key }) => {
              const org = organizations.find(({ id }) => id.toString() === key);
              if (org) {
                const pathName = history.location.pathname || '';
                // org that can visit rn-sdk docs
                // const canVisitRNDocs = org?.is_sea || org?.is_seatalk;
                let redirectUrl = '';
                let query = '';
                if (pathName.indexOf('/company') > -1) {
                  redirectUrl = `/company/${org.id}/apps`;
                } else if (pathName.indexOf('/docs') > -1) {
                  // redirectUrl =
                  //   pathName.indexOf('/docs/rn-sdk') > -1 && !canVisitRNDocs
                  //     ? `/docs`
                  //     : pathName;
                  // switch org and search
                  redirectUrl = pathName;
                  const { keyword } = qs.parse(history.location.search, {
                    ignoreQueryPrefix: true,
                  });
                  if (pathName.indexOf('/docs/search') > -1 && keyword) {
                    query = `keyword=${keyword}`;
                  }
                }
                setActiveOrganization(org);
                history.replace({
                  pathname: redirectUrl,
                  search: query,
                });
              }
            }}
          >
            {organizations.map(({ id, name, icon_src }) => (
              <Menu.Item key={id}>
                <Row justify="space-between">
                  <Col>
                    <Space>
                      <Avatar shape="square" size={32} src={icon_src} />
                      {name}
                    </Space>
                  </Col>
                  <Col>
                    <Row
                      className={styles.tick}
                      justify="center"
                      align="middle"
                    >
                      {activeOrganization.id === id && (
                        <SelectedIcon color={palette.blue} size={14} />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Menu.Item>
            ))}
          </Menu>
        ) : (
          <></>
        )
      }
      trigger={['click']}
    >
      <Space
        className={organizations.length > 1 && styles.space}
        direction="horizontal"
        align="center"
        size={12}
      >
        <Avatar shape="square" size={32} src={activeOrganization.icon_src} />
        <span className={styles.name}>{activeOrganization.name}</span>
        {organizations.length > 1 && (
          <DropdownIcon className={styles.dropdown} color="#fff" />
        )}
      </Space>
    </Dropdown>
  );
};

export default Org;
