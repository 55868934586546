import React, { useState, useEffect, useCallback } from 'react';
import Cropper, { Point, Area } from 'react-easy-crop';
import classnames from 'classnames';
import { Button, Modal, Upload } from 'antd';
import CoralButton, { ButtonVariants } from '@seaweb/coral/components/Button';
import { ColorTypes } from '@seaweb/coral/components/ThemeProvider';
import throttle from 'lodash/throttle';
import {
  PlusOutlined,
  MinusOutlined,
  CloseCircleFilled,
} from '@ant-design/icons';

import locale from 'common/locale';
import { getImageType } from 'utils/apps';

import {
  Status,
  Orientation,
  accept,
  MAX_SIZE,
  MIN_LENGTH,
  MAX_LENGTH,
  step,
  ratio,
} from './consts';

import styles from './AvatarCrop.module.less';

interface Props {
  file: File;
  loading: boolean;
  onUpload: (blob: Blob) => void;
  onCancel: () => void;
}

const AvatarCrop: React.FC<Props> = ({ file, loading, onUpload, onCancel }) => {
  const [status, setStatus] = useState(Status.Init);
  const [dataUrl, setDataUrl] = useState('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  const [image, setImage] = useState(new Image());
  const [imageType, setImageType] = useState<string>();
  const [imageStyle, setImageStyle] = useState<React.CSSProperties>();
  const [orientation, setOrientation] = useState<Orientation>();

  const checkFile = (file: File): boolean => {
    const acceptList = accept.split(',');
    return acceptList.includes(file.type) && file.size <= MAX_SIZE;
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => {
        resolve(image);
      });
      image.addEventListener('error', (e) => {
        reject(e);
      });
      image.src = url;
    });

  const readFile = useCallback((file: File): void => {
    if (!checkFile(file)) {
      setDataUrl('');
      setStatus(Status.Error);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', async () => {
      if (typeof reader.result === 'string') {
        try {
          const image = await createImage(reader.result);
          const imageType = getImageType(reader.result);
          if (image.width < MIN_LENGTH || image.height < MIN_LENGTH) {
            setDataUrl('');
            setStatus(Status.Error);
            return;
          }
          if (image.width > image.height) {
            setOrientation(Orientation.Horizontal);
            setZoom(1);
          } else {
            setOrientation(Orientation.Vertical);
            setZoom(ratio);
          }
          setCrop({ x: 0, y: 0 });
          setImage(image);
          setImageType(imageType);
          setDataUrl(reader.result);
          setStatus(Status.Init);
        } catch {
          setDataUrl('');
          setStatus(Status.Error);
        }
      }
    });
    reader.readAsDataURL(file);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCropAreaChange = useCallback(
    throttle((cropArea: Area) => {
      if (orientation === Orientation.Horizontal) {
        const scale = 100 / cropArea.width;
        setImageStyle({
          transform: `translate3d(${`${-cropArea.x * scale}%`}, ${`${-(
            cropArea.y * scale
          )}%`}, 0) scale3d(${scale}, ${scale}, 1)`,
          width: '100%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
        });
      } else {
        const scale = 100 / cropArea.height;
        setImageStyle({
          transform: `translate3d(${`${-cropArea.x * scale}%`}, ${`${
            -cropArea.y * scale
          }%`}, 0) scale3d(${scale}, ${scale}, 1)`,
          height: '100%',
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
        });
      }
    }, 50),
    [orientation]
  );

  const cropImage = (croppedAreaPixels: Area): void => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }
    const length = Math.min(croppedAreaPixels.width, MAX_LENGTH);
    canvas.width = length;
    canvas.height = length;
    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      length,
      length
    );
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          return;
        }
        onUpload(blob);
      },
      imageType || 'image/png',
      1
    );
  };

  useEffect(() => {
    readFile(file);
  }, [file, readFile]);

  return (
    <Modal
      title={locale('app_icon_modal_title')}
      width={420}
      visible
      centered
      footer={[
        <CoralButton
          key="cancel"
          onClick={onCancel}
          variant={ButtonVariants.Outlined}
        >
          {locale('action_cancel')}
        </CoralButton>,
        <CoralButton
          style={{ marginLeft: '16px' }}
          disabled={!dataUrl || loading}
          key="ok"
          onClick={(): void => {
            cropImage(croppedAreaPixels as Area);
          }}
          colorType={ColorTypes.Primary}
        >
          {locale('action_confirm')}
        </CoralButton>,
      ]}
      onCancel={onCancel}
      maskClosable={false}
      destroyOnClose
      transitionName=""
      maskTransitionName=""
    >
      <div className={styles.container}>
        <div className={styles.cropArea}>
          <div className={styles.cropBox}>
            {status === Status.Init ? (
              <Cropper
                classes={{
                  cropAreaClassName: 'CropperCropArea',
                }}
                image={dataUrl}
                crop={crop}
                zoom={zoom}
                aspect={1}
                showGrid={false}
                zoomWithScroll={false}
                objectFit={
                  orientation === Orientation.Horizontal
                    ? 'vertical-cover'
                    : 'horizontal-cover'
                }
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropAreaChange={onCropAreaChange}
                onCropComplete={(_, croppedAreaPixels: Area): void => {
                  setCroppedAreaPixels(croppedAreaPixels);
                }}
              />
            ) : (
              <Upload.Dragger
                className={styles.dragger}
                showUploadList={false}
                accept={accept}
                beforeUpload={(file): boolean => {
                  readFile(file);
                  return false;
                }}
              >
                <CloseCircleFilled style={{ color: 'red' }} />
                <p className="hint">{locale('app_icon_type_hint')}</p>
                <p className="hint">{locale('app_icon_size_hint')}</p>
                <p className="hint">{locale('app_icon_pixel_hint')}</p>
              </Upload.Dragger>
            )}
          </div>
          <div className={styles.controller}>
            <Upload
              showUploadList={false}
              accept={accept}
              beforeUpload={(file): boolean => {
                readFile(file);
                return false;
              }}
            >
              <Button
                type="link"
                onClick={(e): void => {
                  e.currentTarget.blur();
                }}
              >
                {locale('app_icon_action_reselect')}
              </Button>
            </Upload>
            <Button
              className={styles.icon}
              icon={<PlusOutlined />}
              disabled={!dataUrl}
              onClick={(e): void => {
                if (orientation === Orientation.Horizontal) {
                  setZoom(Math.min(image.height / MIN_LENGTH, zoom + step));
                } else {
                  setZoom(
                    Math.min((image.width / MIN_LENGTH) * ratio, zoom + step)
                  );
                }
                e.currentTarget.blur();
              }}
            />
            <Button
              className={styles.icon}
              icon={<MinusOutlined />}
              disabled={!dataUrl}
              onClick={(e): void => {
                setZoom(
                  Math.max(
                    orientation === Orientation.Horizontal ? 1 : ratio,
                    zoom - step
                  )
                );
                e.currentTarget.blur();
              }}
            />
          </div>
        </div>
        <div className={styles.previewArea}>
          <div className={styles.text}>{locale('app_icon_result_preview')}</div>
          {dataUrl && (
            <div className={styles.viewer}>
              <div className={styles.iconDisplayBox}>
                <div className={styles.iconBox}>
                  <img
                    className={styles.image}
                    style={imageStyle}
                    alt=""
                    src={dataUrl}
                  />
                </div>
              </div>
              <div className={styles.iconLabel}>
                {locale('app_icon_light_mode_label')}
              </div>
            </div>
          )}
          {dataUrl && (
            <div className={styles.viewer}>
              <div
                className={classnames(styles.iconDisplayBox, styles.darkViewer)}
              >
                <div className={styles.iconBox}>
                  <img
                    className={styles.image}
                    style={imageStyle}
                    alt=""
                    src={dataUrl}
                  />
                </div>
              </div>
              <div className={styles.iconLabel}>
                {locale('app_icon_dark_mode_label')}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AvatarCrop;
