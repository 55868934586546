import React from 'react';
import { useColorImage } from '@seaweb/coral/components/Avatar/utils';

import styles from './AvatarImg.module.less';

interface Props {
  src: string;
  size: number;
  radius?: string | number;
  alt?: string;
}

const AvatarImg: React.FC<Props> = ({ src, alt, size, radius }) => {
  const style = { width: size, height: size, borderRadius: radius };
  const emptyImg = useColorImage({ size, color: 'rgba(0, 0, 0, 0)' });
  return (
    <div className={styles.avatarBox}>
      <img
        src={src || emptyImg}
        // eslint-disable-next-line no-console
        onError={() => console.log('Avatar error')}
        className={styles.avatar}
        alt={alt}
        style={style}
      />
    </div>
  );
};

export default AvatarImg;
