import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { Route, Switch } from 'react-router-dom';
import Pusher from '@seaweb/coral/components/Pusher';
import Layout, { Navbar } from '@seaweb/coral/components/Layout';

import * as serviceWorker from 'serviceWorker';
import Login from 'components/login';
import './sentry';
import BrowserRouterUserConfirm from 'components/browser-router-user-confirm';
import AnnouncementBanner from 'components/announcement-banner';
import Nav from 'components/nav';
import Spinner from 'components/spinner';
import { ErrorBoundary } from 'hooks/useHttpRequest';
import {
  CurrentUserProvider,
  ActiveOrganizationProvider,
  OrganizationsProvider,
  FeatureToggleProvider,
} from 'providers';
import AppRoute from 'pages/AppRoute';
import { LOGIN_COOKIE } from 'utils/storage';
import reportWebVitals from 'utils/reportWebVitals';

import './styles/index.less';

const GlobalApp: React.FC = () => {
  const [cookies] = useCookies([LOGIN_COOKIE]);
  const isLoggedIn = !!cookies[LOGIN_COOKIE];

  return (
    <Pusher>
      <BrowserRouterUserConfirm>
        <Switch>
          {!isLoggedIn && <Route exact path="/login" component={Login} />}
          <OrganizationsProvider>
            <ActiveOrganizationProvider>
              <FeatureToggleProvider>
                <CurrentUserProvider>
                  <AnnouncementBanner />
                  <Layout>
                    <Navbar>
                      <Nav />
                    </Navbar>
                    <Layout>
                      <Suspense fallback={<Spinner />}>
                        <ErrorBoundary>
                          <AppRoute isLoggedIn={isLoggedIn} />
                        </ErrorBoundary>
                      </Suspense>
                    </Layout>
                  </Layout>
                </CurrentUserProvider>
              </FeatureToggleProvider>
            </ActiveOrganizationProvider>
          </OrganizationsProvider>
        </Switch>
      </BrowserRouterUserConfirm>
    </Pusher>
  );
};

(async () => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable-next-line global-require, @typescript-eslint/no-var-requires */
    const { worker } = require('./mocks/browser');
    await worker.start({
      serviceWorker: {
        url: '/mockServiceWorker.js',
      },
      onUnhandledRequest: ({ method, url: { href } }: any) => {
        // eslint-disable-next-line no-console
        console.warn('[MSW]Unhandled request.', { method, href });
      },
      waitUntilReady: true,
    });
  }
})();

ReactDOM.render(<GlobalApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

reportWebVitals();
