import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariants } from '@seaweb/coral/components/Button';
import QuestionOIcon from '@seaweb/coral/icons/QuestionO';
import { Uint64BE } from 'int64-buffer';
import { ColorTypes } from '@seaweb/coral/components/ThemeProvider';
import { useLocation, useHistory } from 'react-router-dom';
import { ToastTypes } from '@seaweb/coral/components/Toast';
import { PusherPositions } from '@seaweb/coral/components/Pusher';

import locale from 'common/locale';
import { submitForm } from 'utils/form';
import { useHttpRequest } from 'hooks/useHttpRequest';
import { GoogleLoginSearchParams, HttpRequestMethod } from 'utils/enums';
import AnnouncementBanner from 'components/announcement-banner';
import usePushToast from 'hooks/usePushToast';
import {
  ESelectedLoginMethod,
  SHOULD_TRACK_SUCCESS_LOGIN_SESSION,
} from 'utils/storage';

import GoogleLogin from './GoogleLogin';
import HowToScan from './HowToScan';
import LoginFailedModal, {
  ELoginErrorType,
  TLoginErrorTypeKey,
} from './LoginFailedModal';

import styles from './Login.module.less';

interface Props {
  onLoginSuccess: (userId: number, token: string) => void;
}

const SectionLeft = styled.div<{ isShowHowToScan: boolean }>`
  margin-right: ${(props) => (props.isShowHowToScan ? 20 : 106)}px;
  transition: margin 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
`;

const LoginImageWrapper = styled.div<{ isShowHowToScan: boolean }>`
  transform: ${(props) =>
    props.isShowHowToScan ? 'translateX(-100px)' : 'translateX(0)'};
  opacity: ${(props) => (props.isShowHowToScan ? 0 : 1)};
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
`;

const SectionRight = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionBottom = styled.div`
  bottom: -16px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)<{ hide: boolean }>`
  color: #1b92f5;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  display: inline-flex;
  width: fit-content;
  margin: auto;
  height: auto;
`;

const StyledDivider = styled.div<{ hide: boolean }>`
  color: #8b8d8f;
  opacity: ${(props) => (props.hide ? 0 : 1)};
  transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  display: flex;
  margin: 16px 0;
  justify-content: center;
  align-items: center;

  hr {
    width: 100%;
    border-top: 1px solid #e1e1e7;
  }

  span {
    margin: 0 16px;
    text-transform: uppercase;
  }
`;

const StyledGoogleLogin = styled(GoogleLogin)<{ isShowHowToScan: boolean }>`
  transform: ${(props) =>
    props.isShowHowToScan ? 'translateY(-80px)' : 'translateY(0)'};
`;

const Login: React.FC<Props> = ({ onLoginSuccess }) => {
  const [postUrl, setPostUrl] = useState('');
  const [loginUrl, setLoginUrl] = useState('');
  const [loginState, setLoginState] = useState('');
  const [showHowToScan, setShowHowToScan] = useState(false);
  const [googleCompletionError, setGoogleCompletionError] =
    useState<TLoginErrorTypeKey | null>(ELoginErrorType.DeactivatedAccount);
  const [loginFailedVisible, setLoginFailedVisible] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const history = useHistory();

  const { request } = useHttpRequest();
  const pushToast = usePushToast();

  const pushErrorToast = (message: string) =>
    pushToast(ToastTypes.Fail, message, false, PusherPositions.MiddleCenter);

  const authCallbackHook = async () => {
    try {
      const response = await request(
        {
          url: 'auth/v2/login',
          method: HttpRequestMethod.Get,
        },
        false
      );
      setPostUrl(`${response.post_url}`);
      // TODO: remove lang at 2022/08/09
      setLoginUrl(`${response.qrcode_url}&lang=en`);
      setLoginState(response.state);
    } catch (err) {
      // By default, this API won't return error
      // This is just for fallback if error happens before BE return (ex: 404, blocked)
      pushErrorToast(locale('login_server_error'));
    }
  };

  // event listener to listen to qr code login iframe
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const authRoot = loginUrl && new URL(loginUrl).origin;
      const { userid, token } = event.data ?? {};
      if (event.origin !== authRoot) return;
      if (!userid || !token) return;

      // Flag for track QR login success
      sessionStorage.setItem(
        SHOULD_TRACK_SUCCESS_LOGIN_SESSION,
        ESelectedLoginMethod.QR
      );

      onLoginSuccess?.(userid, token);
      submitForm(
        postUrl,
        { token: new Uint64BE(token, 16).toString(10) },
        HttpRequestMethod.Post
      );
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [loginUrl, postUrl, onLoginSuccess]);

  // Expected to be run just once to check query & retrieve login information
  // Google login response query: https://developers.google.com/identity/protocols/oauth2/javascript-implicit-flow#handlingresponse
  useEffect(() => {
    const GoogleError = searchParams.get(GoogleLoginSearchParams.Error);
    const GoogleCompleteError = searchParams.get(
      GoogleLoginSearchParams.CompleteError
    ) as ELoginErrorType;
    const GoogleCode = searchParams.get(GoogleLoginSearchParams.Code);
    const GoogleState = searchParams.get(GoogleLoginSearchParams.State);

    if (
      [GoogleError, GoogleCompleteError, GoogleCode, GoogleState].some(
        (value) => value
      )
    ) {
      // Error from Google
      if (GoogleError) {
        pushErrorToast(locale('login_google_auth_failed'));
      }

      // Error from Complete API
      if (GoogleCompleteError) {
        if (GoogleCompleteError === ELoginErrorType.UnexpectedError) {
          pushErrorToast(locale('login_server_error'));
        } else {
          setGoogleCompletionError(GoogleCompleteError);
          setLoginFailedVisible(true);
        }
      }

      // Success from Google
      if (GoogleCode) {
        // Flag for track QR login success
        sessionStorage.setItem(
          SHOULD_TRACK_SUCCESS_LOGIN_SESSION,
          ESelectedLoginMethod.Google
        );

        submitForm(
          '/api/auth/v2/login/complete/google',
          { code: GoogleCode, state: GoogleState },
          HttpRequestMethod.Get
        );
      }

      history.replace('/login');
    }

    authCallbackHook();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id={styles.pageContainer}>
      <AnnouncementBanner className={styles.announcement} />

      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.logo}></div>
          <div className={styles.tagContainer}>
            <div className={styles.tag}>
              {locale('login_header_open_platform')}
            </div>
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.main}>
            <SectionLeft
              className={styles.left}
              isShowHowToScan={showHowToScan}
            >
              <HowToScan
                isShown={showHowToScan}
                hideHowToScan={() => setShowHowToScan(false)}
              />
              <LoginImageWrapper isShowHowToScan={showHowToScan}>
                <div className={styles.workLogo}></div>
                <div className={styles.appName}>
                  {locale('login_content_app_name')}
                </div>
                <div className={styles.appDescription}>
                  {locale('login_content_app_description')}
                </div>
              </LoginImageWrapper>
            </SectionLeft>
            <SectionRight>
              <iframe
                className={styles.auth}
                src={loginUrl}
                title="qr"
                frameBorder="0"
                scrolling="no"
              ></iframe>
              <SectionBottom>
                <StyledButton
                  variant={ButtonVariants.Text}
                  onClick={() => setShowHowToScan(true)}
                  hide={showHowToScan}
                >
                  {locale('login_how_to_scan')}
                  <QuestionOIcon
                    colorType={ColorTypes.Primary}
                    style={{ marginLeft: 4 }}
                  />
                </StyledButton>
                <StyledDivider hide={showHowToScan}>
                  <hr />
                  <span>{locale('login_or_divider')}</span>
                  <hr />
                </StyledDivider>
                <StyledGoogleLogin
                  isShowHowToScan={showHowToScan}
                  state={loginState}
                />
              </SectionBottom>
            </SectionRight>
          </div>
        </div>
        <div className={styles.copyright}>
          {locale('login_copyright', { year: new Date().getFullYear() })}
        </div>
      </div>

      <LoginFailedModal
        errorType={googleCompletionError as TLoginErrorTypeKey}
        visible={loginFailedVisible}
        closeModal={() => setLoginFailedVisible(false)}
      />
    </div>
  );
};

export default Login;
