import { HttpRequestMethod } from 'utils/enums';

interface AppsRequestParams {
  companyId: ID;
  keyword?: string;
  page?: number;
  pageSize?: number;
}

export function getApps({
  companyId,
  page,
  pageSize,
  keyword,
}: AppsRequestParams): RequestType {
  return {
    url: 'app/v2/apps',
    method: HttpRequestMethod.Get,
    options: {
      searchParams: {
        company_id: companyId,
        page,
        per_page: pageSize,
        keyword,
      },
    },
  };
}

export function createApp({
  companyId,
  name,
  icon,
  uss_icon,
  description,
}: {
  companyId: number;
  name: string;
  icon: string;
  uss_icon?: string;
  description?: string;
}): RequestType {
  return {
    url: 'app/v2/apps',
    method: HttpRequestMethod.Post,
    options: {
      json: {
        company_id: companyId,
        name,
        icon,
        uss_icon,
        description,
      },
    },
  };
}

export function getTestOrg(companyId: number): RequestType {
  return {
    url: 'company/get_test_org',
    // url: 'http://hub-mock.i.test.sz.shopee.io/sop/company/get_test_org',
    method: HttpRequestMethod.Get,
    options: {
      searchParams: {
        company_id: companyId,
      },
    },
  };
}

export function createTestOrg(companyId: number): RequestType {
  return {
    url: 'company/create_test_org',
    method: HttpRequestMethod.Post,
    options: {
      searchParams: {
        company_id: companyId,
      },
    },
  };
}

export function updateTestOrg(
  companyId: number,
  testOrgId: number,
  developers: (number | string)[]
) {
  return {
    url: 'company/update_test_org',
    method: HttpRequestMethod.Post,
    options: {
      searchParams: {
        company_id: companyId,
      },
      json: {
        test_company: {
          id: testOrgId,
          developers,
        },
      },
    },
  };
}

export function getSSOToken(): RequestType {
  return {
    url: 'auth/sso_token',
    method: HttpRequestMethod.Get,
  };
}
