/**
 * What this file ?
 * Provide ErrorBoudary for calling API.
 * Provide useHttpRequest for component to use
 *
 */

import React, { useCallback, createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ky from 'ky';
import {
  usePusherContext,
  PusherPositions,
} from '@seaweb/coral/components/Pusher';
import Toast, { ToastTypes } from '@seaweb/coral/components/Toast';

import ErrorScreen from 'components/error-screen';
import { getErrorMsg, formatReponseValidationErrors } from 'utils/response';
import { checkURL } from 'utils/form';
import { checkJson } from 'utils/helpers';
import {
  HttpRequestError,
  HttpRequestMethod,
  RequestStatus,
} from 'utils/enums';

const server = `${window.location.origin}/api/`;

// ERROR BOUNDARY
interface ErrorStatusContextType {
  // eslint-disable-next-line @typescript-eslint/ban-types
  setErrorStatusCode: (errorCode: HttpRequestError) => {} | void;
}

const ErrorBoundaryContext = createContext({} as ErrorStatusContextType);

const ErrorBoundary: React.FC = ({ children }: any) => {
  const history = useHistory();
  const [errorStatusCode, setErrorStatusCode] = useState<HttpRequestError>();
  React.useEffect(() => {
    const unlisten = history.listen(() => setErrorStatusCode(0));
    return unlisten;
  });

  const renderContent = () => {
    if (errorStatusCode && errorStatusCode >= 400 && errorStatusCode < 500) {
      return ErrorScreen(HttpRequestError.Error400);
    } else if (errorStatusCode && errorStatusCode >= 500) {
      return ErrorScreen(HttpRequestError.Error500);
    }

    return children;
  };

  const contextPayload = React.useMemo(
    () => ({ setErrorStatusCode }),
    [setErrorStatusCode]
  );

  return (
    <ErrorBoundaryContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorBoundaryContext.Provider>
  );
};

function useHttpRequest() {
  const { setErrorStatusCode } = React.useContext(ErrorBoundaryContext);
  const [requestStatus, setRequestStatus] = useState(RequestStatus.IDLE);
  const { push } = usePusherContext();
  const pushError = useCallback((msg: string) => {
    const toast = <Toast type={ToastTypes.Fail}>{msg}</Toast>;
    push(toast, {
      position: PusherPositions.TopCenter,
    });
    // eslint-disable-next-line
  }, []);

  const request = useCallback(
    async (
      { url, options, method }: RequestType,
      catchError = true as boolean,
      showToast = false as boolean
    ) => {
      let errorMsg = '';
      try {
        let res;
        setRequestStatus(RequestStatus.REQUESTING);
        const serverUrl = checkURL(url) ? url : server + url;
        if (method === HttpRequestMethod.Get) {
          res = await ky.get(serverUrl, options);
        } else {
          res = await ky.post(serverUrl, options);
        }

        setRequestStatus(RequestStatus.SUCCESS);

        const response = await res.json();
        if (response && (response.validation_errors || response.code !== 0)) {
          errorMsg = getErrorMsg(response.code) || response?.message || '';
          if (showToast) {
            pushError(errorMsg);
          }
          return {
            ...response,
            errors:
              response.validation_errors &&
              formatReponseValidationErrors(response.validation_errors),
            errorMsg,
          };
        } else {
          return response;
        }
      } catch (err) {
        const error = err as ky.HTTPError;
        const reqStatus = error?.response?.status;
        const responseText = await error.response?.text();
        const errorRes: any = checkJson(responseText) || {};
        // eslint-disable-next-line no-console
        console.error('show response:', error, reqStatus, errorRes);
        errorMsg = getErrorMsg(errorRes.code) || errorRes?.message || '';

        setRequestStatus(RequestStatus.FAILED);
        if (catchError) {
          setErrorStatusCode && setErrorStatusCode(reqStatus);
        } else if (showToast) {
          pushError(errorMsg);
          return { ...errorRes, ...error, errorMsg, reqStatus };
        } else {
          throw error;
        }
      }
    },
    [setErrorStatusCode, setRequestStatus, pushError]
  );
  return { request, requestStatus };
}

export { ErrorBoundary, useHttpRequest };
