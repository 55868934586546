import React, { useEffect, useRef, useState } from 'react';
import TextField, {
  TextFieldCharCount,
  TextArea,
} from '@seaweb/coral/components/TextField';
import Field, { LabelPositions } from '@seaweb/coral/components/Field';
import { Controller, Control } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/form';
import { substr } from 'stringz';

import AvatarUpload from 'components/avatar-upload';
import CharCount from 'components/shared/CharCount';
import locale from 'common/locale';
import { MAX_APP_NAME, MAX_APP_DESCRIPTION } from 'utils/constants';

import styles from './BasicAppInfoForm.module.less';
import { FormInputs } from 'pages/apps-tabs/apps-info/EditBasicInfoDialog';

interface Avatar {
  code?: number;
  icon_name: string;
  uss_icon: string;
  icon_src: string;
}

export interface BasicAppInfoInputs {
  avatar: Avatar;
  name: string;
  description: string;
}

export interface AppInfoFormProps {
  activeOrganization: Organization;
  formHook: {
    control: Control;
    errors: FieldErrors;
    setError: any;
    clearErrors: any;
    getValues: any;
    setValue: any;
    watch: any;
    trigger: any;
  };
  labelWidthPercent?: number;
  fieldWidthPercent?: number;
  setButtonState?: (status: boolean) => void;
}

const BasicAppInfoForm: React.FC<AppInfoFormProps> = ({
  formHook: { control, errors, setError, clearErrors, getValues },
  labelWidthPercent,
  activeOrganization,
  setButtonState,
}) => {
  const fieldContainerProps = {
    style: { width: '400px' },
  };

  const formInitialValue = useRef<FormInputs | null>(null);
  const flagChanges = useRef<Set<keyof FormInputs>>(new Set());
  const [isAllEmpty, setIsAllEmpty] = useState(true);

  useEffect(() => {
    const { avatar, description = '', name = '' } = getValues();

    const initialValue = {
      avatar: avatar.icon_name || '',
      description,
      name,
    };

    formInitialValue.current = { ...initialValue };
  }, []);

  const checkChanges = (field: keyof FormInputs, value: any) => {
    // To avoid racing condition. Fetching data triggers onChange func on the field but initial value haven't been set
    if (!formInitialValue.current || !setButtonState) return;

    const prevData = formInitialValue.current[field];

    if (prevData === value) {
      flagChanges.current.delete(field);
    } else {
      flagChanges.current.add(field);
    }

    // If set is not empty, enable submit button
    if (flagChanges.current.size !== 0) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  };

  const labelContainerProps = {
    style: {
      width: `${labelWidthPercent ?? 30}%`,
      flexBasis: `${labelWidthPercent ?? 30}%`,
    },
  };

  return (
    <div className={styles.appInfoForm}>
      <Field
        labelProps={labelContainerProps}
        containerProps={fieldContainerProps}
        label={locale('app_list_create_app_app_icon')}
        labelPosition={LabelPositions.Left}
        required
        invalid={!!errors.avatar}
        message={errors.avatar?.message}
      >
        <Controller
          as={AvatarUpload}
          control={control}
          name="avatar"
          rules={{
            validate: (avatar: Avatar): string | boolean => {
              if (
                avatar &&
                (avatar.uss_icon || avatar.icon_name) &&
                avatar.icon_src
              ) {
                setButtonState && checkChanges('avatar', avatar.icon_name);
                return true;
              }
              return locale('app_list_create_app_missing_icon');
            },
          }}
          companyId={activeOrganization.id}
          action={`/api/app/v2/app/icon`}
          setError={setError}
          clearErrors={clearErrors}
        />
      </Field>
      <Field
        labelProps={labelContainerProps}
        containerProps={fieldContainerProps}
        label={locale('app_list_create_app_app_name')}
        labelPosition={LabelPositions.Left}
        required
        invalid={!!errors.name}
        message={errors.name?.message}
      >
        <Controller
          name="name"
          control={control}
          rules={{
            validate: (value: string): string | boolean => {
              if (!value) {
                return locale('app_list_create_app_missing_name');
              }
              if (value.length > MAX_APP_NAME) {
                return locale('app_list_create_app_too_long');
              }

              return true;
            },
          }}
          render={({ value, onChange, onBlur }) => (
            <TextField
              value={value}
              fullWidth
              onBlur={() => {
                if (value) {
                  onChange(value.trim());
                }
                onBlur();
              }}
              className={styles.inputText}
              rightElement={<TextFieldCharCount outOf={MAX_APP_NAME} />}
              placeholder={locale('app_list_create_app_app_name_placeholder')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(substr(e.target.value, 0, MAX_APP_NAME));
                setButtonState &&
                  checkChanges(
                    'name',
                    substr(e.target.value, 0, MAX_APP_NAME).trim()
                  );
              }}
            />
          )}
        />
      </Field>
      <Field
        labelProps={labelContainerProps}
        containerProps={fieldContainerProps}
        label={locale('app_list_create_app_app_desc')}
        labelPosition={LabelPositions.Left}
        required
        invalid={!!errors.description}
        message={errors.description?.message}
      >
        <Controller
          control={control}
          name="description"
          rules={{
            required: locale('app_list_create_app_missing_description'),
          }}
          render={({ value, onChange, onBlur }) => (
            <TextArea
              fullWidth
              minRows={4}
              className={styles.inputText}
              maxRows={8}
              placeholder={locale('app_list_create_app_app_desc_placeholder')}
              bottomElement={<CharCount outOf={MAX_APP_DESCRIPTION} />}
              value={value}
              onBlur={() => {
                if (value) {
                  onChange(value.trim());
                }
                onBlur();
              }}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                onChange(substr(e.target.value, 0, MAX_APP_DESCRIPTION));
                setButtonState &&
                  checkChanges(
                    'description',
                    substr(e.target.value, 0, MAX_APP_DESCRIPTION).trim()
                  );
              }}
            />
          )}
        />
      </Field>
    </div>
  );
};

export default BasicAppInfoForm;
