import React, { useContext } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import AppsList from 'pages/apps-tabs/apps-list';
import { ActiveOrganizationContext, OrganizationsContext } from 'providers';
// import NoPermissions from 'components/no-permission-screen';
import Spinner from 'components/spinner';
import { getQueryByName } from 'utils/helpers';
import { GoogleLoginSearchParams } from 'utils/enums';

const Apps = React.lazy(() => import('pages/apps-tabs/apps'));
const AppWizard = React.lazy(() => import('pages/apps-tabs/apps-wizard'));
const Docs = React.lazy(() => import('pages/docs-tabs'));
const SearchPage = React.lazy(() => import('pages/search-page'));

interface AppRouteProps {
  isLoggedIn: boolean;
}

const AppRoute: React.FC<AppRouteProps> = ({ isLoggedIn }) => {
  const history = useHistory();
  const { activeOrganization } = useContext(ActiveOrganizationContext);
  const [cookies, , removeCookie] = useCookies();
  const { isLoadingOrganizations } = useContext(OrganizationsContext);
  const hasRedirection = !!cookies.destination;

  if (!isLoggedIn) {
    const searchParams = new URLSearchParams(history.location.search);
    if (searchParams.get(GoogleLoginSearchParams.CompleteError)) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            search: `login_error=${searchParams.get(
              GoogleLoginSearchParams.CompleteError
            )}`,
          }}
        />
      );
    }

    const currentPath = history.location.pathname + history.location.search;
    const destinationSearchParams =
      currentPath === '/'
        ? ''
        : `destination=${encodeURIComponent(currentPath)}`;

    return (
      <Redirect to={{ pathname: '/login', search: destinationSearchParams }} />
    );
  }

  const query = history.location.search || '';
  const destination = getQueryByName('destination', query);

  if (destination) {
    return <Redirect to={destination} />;
  }

  // Login redirection
  if (hasRedirection) {
    const { destination } = cookies;
    removeCookie('destination');
    return <Redirect to={destination} />;
  }

  if (isLoadingOrganizations) {
    return <Spinner />;
  }

  const pathName = history.location.pathname || '';
  // login visit
  if (pathName === '/' && activeOrganization.id) {
    const loginRedirect = `/company/${activeOrganization.id}/apps`;
    return <Redirect to={loginRedirect} />;
  }

  return (
    <Switch>
      <Route path="/docs/search" component={SearchPage} />
      <Route path="/docs/:articleUrl" component={Docs} />
      <Route path="/docs" component={Docs} />
      {/* if user without a valid organization
       * try to access Apps tab through modifying URL,
       * we will redirect user to Documentation tab */}
      {!activeOrganization?.id ? (
        <Redirect to="/docs" />
      ) : (
        <Switch>
          <Route
            path="/company/:companyId/apps/wizard"
            render={() => <AppWizard />}
          />
          <Route
            exact
            path="/company/:companyId/apps"
            render={() => <AppsList />}
          />
          <Route path="/company/:companyId/apps/:id" component={Apps} />
          <Redirect to={`/company/${activeOrganization.id}/apps`} />
        </Switch>
      )}
    </Switch>
  );
};

export default AppRoute;
