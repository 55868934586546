import { HttpRequestMethod } from 'utils/enums';

export function getAuthMe(): RequestType {
  return {
    url: 'auth/v2/me',
    method: HttpRequestMethod.Get,
  };
}

export function getFeatureToggleList(
  employeeId: number,
  orgId: number
): RequestType {
  return {
    // feature toggle
    url: 'c3dpdGNocHJv',
    method: HttpRequestMethod.Post,
    options: {
      json: {
        employee_code: employeeId.toString(),
        organization_code: orgId.toString(),
      },
    },
  };
}
