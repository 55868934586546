import helper from './gtag';

// List of GA Events
export const TRACK_GOOGLE_LOGIN_ATTEMPT = 'sop_login_google_attempt';
export const TRACK_GOOGLE_LOGIN_SUCCESS = 'sop_login_google_success';
export const TRACK_QR_LOGIN_SUCCESS = 'sop_login_qr_success';

interface ISendTrackingEventProps {
  name: string;
  parameters?: Record<string, string>;
}

export const sendTrackingEvent = ({
  name,
  parameters,
}: ISendTrackingEventProps) => {
  helper.event(name, parameters || {});
};
