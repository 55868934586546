import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useHttpRequest } from 'hooks/useHttpRequest';

import { getFeatureToggleList } from './api';
import { ActiveOrganizationContext } from './ActiveOrganizationProvider';

type Features = Record<string, string | undefined>;
type GetToggleValueFunc = <V = string | number>(
  toggleID: string | number,
  defaultVal?: V
) => V;

type FeatureToggleContext = {
  features: Features;
  getToggleValue: GetToggleValueFunc;
};

export const FeatureContext = createContext<FeatureToggleContext>({
  features: {},
  getToggleValue: () => undefined as any,
});

export const useFeatureToggleContext = () => useContext(FeatureContext);

export const useFeatures = (featureIds: string[]) => {
  const { features } = useFeatureToggleContext();
  return featureIds.map((id) => features[id]);
};

const FeatureToggleProvider: FC = ({ children }) => {
  const [features, setFeatures] = useState<Features>({});

  const { request } = useHttpRequest();
  const {
    activeOrganization: { employee_id, id: organizationId },
  } = useContext(ActiveOrganizationContext);

  useEffect(() => {
    if (!employee_id) return;
    request(getFeatureToggleList(employee_id, organizationId))
      .then(({ tegglos }) => {
        if (!Array.isArray(tegglos)) throw new Error();
        const featureMap: Features = {};
        for (const { tegglo_id, tegglo_val } of tegglos) {
          featureMap[tegglo_id] = tegglo_val;
        }
        setFeatures(featureMap);
      })
      .catch(() => {
        /* do nothing */
      });
  }, [request, employee_id, organizationId]);

  // although this is a simple function, use useCallback
  // to prevent re-render on this context consumer.
  const getToggleValue = useCallback<GetToggleValueFunc>(
    (tID, defVal) => {
      if (features[tID]) return features[tID] as any;
      if (defVal) return defVal;
      return undefined;
    },
    [features]
  );

  const value: FeatureToggleContext = {
    features,
    getToggleValue,
  };

  return (
    <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
  );
};

export default FeatureToggleProvider;
