import dayjs from 'dayjs';
import Polyglot from 'node-polyglot';
import React from 'react';

export enum LanguageEnum {
  EN = 'en',
  ZH = 'zh',
  ZH_TW = 'zh-tw',
  ZH_HK = 'zh-hk',
  ZH_CN = 'zh-cn',
  VI = 'vi',
  ID = 'id',
  TH = 'th',
}
type LanguageStructor<T = any> = Partial<{ [key in LanguageEnum]: T }>;

// import translation json files
export const languages: LanguageEnum[] = [
  LanguageEnum.EN,
  LanguageEnum.ZH_TW,
  LanguageEnum.ZH_CN,
  LanguageEnum.VI,
  LanguageEnum.ID,
  LanguageEnum.TH,
];
export const languageNames: LanguageStructor<string> = {
  [LanguageEnum.EN]: 'English',
  [LanguageEnum.ID]: 'Bahasa Indonesia',
  [LanguageEnum.VI]: 'Tiếng Việt',
  [LanguageEnum.TH]: 'ภาษาไทย',
  [LanguageEnum.ZH_TW]: '繁體中文',
  [LanguageEnum.ZH_CN]: '简体中文',
};

const translations: LanguageStructor<Polyglot> = languages.reduce(
  (result: LanguageStructor<Polyglot>, lang: LanguageEnum) => ({
    ...result,
    [lang]: new Polyglot({
      locale: lang,
      // eslint-disable-next-line import/no-dynamic-require, global-require
      phrases: require(`./transify/${lang}.json`),
      interpolation: { prefix: '<%=', suffix: '%>' },
    }),
  }),
  {}
);
// eslint-disable-next-line import/no-dynamic-require, ,global-require
languages.map((lang) => require(`dayjs/locale/${lang}.js`));

function restrictLanguageValue(lang: LanguageEnum) {
  if (lang === LanguageEnum.ZH) return LanguageEnum.ZH_CN;
  if (lang === LanguageEnum.ZH_HK) return LanguageEnum.ZH_TW;
  if (!languages.includes(lang)) return LanguageEnum.EN;
  return lang;
}

function getDomainDefaultLanguage() {
  const tld = window.location.origin.split('.').pop();
  switch (tld) {
    case 'tw':
      return LanguageEnum.ZH_TW;
    case 'th':
      return LanguageEnum.TH;
    case 'id':
      return LanguageEnum.ID;
    case 'vn':
      return LanguageEnum.VI;
    default:
      return LanguageEnum.EN;
  }
}

// what's the current language?
const domainLanguage = getDomainDefaultLanguage();
const restrictedNavigatorLanguage = restrictLanguageValue(
  navigator.language as LanguageEnum
);
const navigatorLanguage =
  restrictedNavigatorLanguage === navigator.language ||
  restrictedNavigatorLanguage !== LanguageEnum.EN;
const value = (
  localStorage.language ||
  (navigatorLanguage && navigator.language) ||
  domainLanguage
).toLowerCase();
const language = restrictLanguageValue(value);
const isEn = language === LanguageEnum.EN;
const isChinese = language.startsWith('zh-');

const translation = translations[language] as Polyglot;
dayjs.locale(language);

export function updateLanguage(lang: LanguageEnum) {
  const newLanguage = restrictLanguageValue(lang);
  if (newLanguage !== language) {
    localStorage.language = newLanguage;
    const url = new URL(window.location.href);
    url.searchParams.delete('lang');
    if (url.toString() === window.location.href) {
      window.location.reload();
    } else {
      window.location.replace(url.toString());
    }
  }
}

export default function locale(
  key: string,
  tplValue?: { [key: string]: string | number }
) {
  // eslint-disable-next-line no-nested-ternary
  return window.location.search === '?transify=false'
    ? key
    : translation.has(key)
    ? translation.t(key, tplValue)
    : `<${key}>`;
}

interface LocaleJSXProps {
  localeKey: string;
  tplValue: { [key: string]: string | number | React.ReactNode };
}

const LocaleJSX: React.FC<LocaleJSXProps> = ({ localeKey, tplValue }) => {
  // if key not found or no tplValue provided
  if (!translation.has(localeKey) || !tplValue) {
    return <>{locale(localeKey)}</>;
  }

  const ReactElementKeys = Object.keys(tplValue).filter((e) =>
    React.isValidElement(tplValue[e])
  );

  // if no ReactNode in tplValue
  if (ReactElementKeys.length === 0) {
    return <>{locale(localeKey)}</>;
  }

  const ReactElementMap: { [key: string]: React.ReactNode } = {};
  // eslint-disable-next-line no-return-assign
  ReactElementKeys.forEach((e) => (ReactElementMap[e] = tplValue[e]));
  const newTplValue = { ...tplValue };

  Object.keys(tplValue).forEach((e) => {
    if (React.isValidElement(newTplValue[e])) {
      delete newTplValue[e];
    }
  });

  const translated = translation.t(localeKey, newTplValue);
  const parts = translated
    .split('<%=')
    .join('|')
    .split('%>')
    .join('|')
    .split('|');

  const finalElements = parts.map((e, idx) => (
    <React.Fragment key={idx}>{ReactElementMap[e] || e}</React.Fragment>
  ));

  return <React.Fragment>{finalElements}</React.Fragment>;
};

export { language, isEn, isChinese, LocaleJSX };
