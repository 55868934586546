export enum Status {
  Init,
  Error,
}

export enum Orientation {
  Horizontal,
  Vertical,
}

export const accept = 'image/jpeg,image/png,image/x-png';
export const MAX_SIZE = 1048576;
export const MIN_LENGTH = 80;
export const MAX_LENGTH = 640;
export const step = 0.05;
export const ratio = 12 / 13;
