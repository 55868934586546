import { rest } from 'msw';
import { ENDPOINTS } from 'apis/constants';
import * as scopePermission from './mock/scopePermission';
import * as capabilities from './mock/capabilities';
import * as appInfo from './mock/appInfo';
import * as loginWithSeatalk from './mock/loginWithSeatalk';

const BASE_URL = '/api/';

export const requestHandlers = [
  rest.get(
    `${BASE_URL}${ENDPOINTS.GET_SCOPE_PERMISSIONS}`,
    scopePermission.getScopePermission
  ),

  rest.get(
    `${BASE_URL}${ENDPOINTS.GET_SCOPE_PERMISSIONS_AUDIT}`,
    scopePermission.getScopePermissionAudit
  ),

  /** Capabilities with department */
  rest.get(
    `${BASE_URL}app/v2/capabilities`,
    capabilities.getCapabilitiesWithDepartment
  ),

  /** Error condition for get capabilities */
  // rest.get(
  //   `${BASE_URL}app/v2/capabilities`,
  //   capabilities.getCapabilitiesCode3
  // )

  /** App info not require approval */
  rest.get(`${BASE_URL}app/v2/profile`, appInfo.getAppInfoNotRequireApproval),

  /** Post basic info */
  rest.post(`${BASE_URL}app/v2/basic_info`, appInfo.postBasicInfoSuccess),

  /** Get login with seatalk config not require approval */
  rest.get(
    `${BASE_URL}app/v2/login_with_seatalk`,
    loginWithSeatalk.getAppInfoNotRequireApproval
  ),
];
