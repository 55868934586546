import { AppCapabilitiesResponse } from 'pages/apps-tabs/apps-wizard/types';

const capabilitiesWithDepartment: AppCapabilitiesResponse = {
  capabilities: [
    {
      capability_type: 1,
      api_permissions: {
        permission_groups: [
          {
            permissions: [
              {
                permission_key: 'send.bot.single.chat.msg',
                permission_name: 'Send Message to Bot Subscriber',
                approval_required: false,
                remark:
                  'Send a message to a subscriber of the bot in the 1-1 chat.',
                status: 3,
                limited_by: 1,
              },
              {
                permission_key: 'send.bot.group.chat.message',
                permission_name: 'Send Message to Group Chat',
                approval_required: false,
                remark:
                  'Send a message to a group chat which the bot has been added to.',
                status: 3,
                limited_by: 3,
              },
            ],
            group_key: 'messaging',
            group_name: 'Messaging',
          },
          {
            permissions: [
              {
                permission_key: 'get.group.chat.info',
                permission_name: 'Get Group Info',
                approval_required: false,
                remark:
                  'Obtain basic information about the group chat, such as the group name, group settings and member list.',
                status: 3,
                limited_by: 3,
              },
              {
                permission_key: 'get.joined.group.chat.list',
                permission_name: 'Get Joined Group Chat List',
                approval_required: false,
                remark: 'Obtain group chats the bot joined.',
                status: 3,
                limited_by: 2,
              },
              {
                permission_key: 'get.message.by.message.id',
                permission_name: 'Get Message by Message ID',
                approval_required: false,
                remark: 'Obtain the message content by the message_id.',
                status: 3,
                limited_by: 3,
              },
            ],
            group_key: 'groupchat',
            group_name: 'Group Chat',
          },
          {
            permissions: [
              {
                permission_key: 'get.employee.profile',
                permission_name: 'Get Contact Profile',
                approval_required: false,
                remark:
                  'Obtain the authorized scope of Contacts data according to the granted permission to the app, including access permission of department lists and user lists.',
                status: 3,
                limited_by: 0,
              },
              {
                permission_key: 'get.bot.subscriber.list',
                permission_name: 'Get Bot Subscriber List',
                approval_required: false,
                remark:
                  "Obtain the subscriber list of the app's bot, including the current subscribers.",
                status: 3,
                limited_by: 2,
              },
            ],
            group_key: 'contacts',
            group_name: 'Contacts',
          },
        ],
      },
      service_scope_departments: [
        {
          department_id: 22235,
          name: 'SeaTalk',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22235,
              name: 'SeaTalk',
            },
          ],
        },
        {
          department_id: 22237,
          name: 'PFMS',
          ancestors: [
            {
              department_id: 399,
              name: 'Sea Group Pte Ltd',
            },
            {
              department_id: 668,
              name: 'Singapore',
            },
            {
              department_id: 708,
              name: 'Shopee',
            },
            {
              department_id: 30929,
              name: 'Product & Engineering',
            },
            {
              department_id: 528,
              name: 'Regional Product',
            },
            {
              department_id: 22238,
              name: 'Enterprise Efficiency',
            },
            {
              department_id: 22237,
              name: 'PFMS',
            },
          ],
        },
      ],
    },
    {
      capability_type: 2,
      api_permissions: {
        permission_groups: [
          {
            permissions: [
              {
                permission_key: 'get.employee.profile',
                permission_name: 'Get Contact Profile',
                approval_required: false,
                remark:
                  'Obtain the authorized scope of Contacts data according to the granted permission to the app, including access permission of department lists and user lists.',
                status: 3,
                limited_by: 0,
              },
            ],
            group_key: 'contacts',
            group_name: 'Contacts',
          },
        ],
      },
      service_scope_departments: [],
    },
    {
      capability_type: 3,
      api_permissions: {
        permission_groups: [
          {
            permissions: [
              {
                permission_key: 'get.employee.profile',
                permission_name: 'Get Contact Profile',
                approval_required: false,
                remark:
                  'Obtain the authorized scope of Contacts data according to the granted permission to the app, including access permission of department lists and user lists.',
                status: 3,
                limited_by: 0,
              },
            ],
            group_key: 'contacts',
            group_name: 'Contacts',
          },
        ],
      },
      service_scope_departments: [],
    },
  ],
  code: 0,
};

const capabilitiesInvalidArgument = {
  code: 3,
};

const capabilitiesNotLoggedIn = {
  code: 16,
};

const capabilitiesNoPermission = {
  code: 7,
};

export const getCapabilitiesWithDepartment = (_: any, res: any, ctx: any) =>
  res(ctx.status(200), ctx.json(capabilitiesWithDepartment));

export const getCapabilitiesCode3 = (_: any, res: any, ctx: any) =>
  res(ctx.status(200), ctx.json(capabilitiesInvalidArgument));

export const getCapabilitiesCode16 = (_: any, res: any, ctx: any) =>
  res(ctx.status(401), ctx.json(capabilitiesNotLoggedIn));

export const getCapabilitiesCode7 = (_: any, res: any, ctx: any) =>
  res(ctx.status(403), ctx.json(capabilitiesNoPermission));
