import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import qs from 'qs';
import { Table } from 'antd';
import { ToastTypes } from '@seaweb/coral/components/Toast';
import Layout, { Main } from '@seaweb/coral/components/Layout';
import Button from '@seaweb/coral/components/Button';
import TextField, { TextFieldClear } from '@seaweb/coral/components/TextField';
import SearchIcon from '@seaweb/coral/components/icons/Search';
import dayjs from 'dayjs';
import Tag from '@seaweb/coral/components/Tag';
import { debounce } from 'lodash';

import locale from 'common/locale';
import { getAppStatus, getAppDistribution } from 'utils/apps';
import {
  AppDetailPageSourceType,
  AppStatus,
  // FEATURE_TOGGLE,
} from 'utils/enums';
import { OrganizationsContext, ActiveOrganizationContext } from 'providers';
import Pagination, { PaginationKey } from 'components/pagination';
// import Spinner from 'components/spinner';
import usePushToast from 'hooks/usePushToast';
import { useHttpRequest } from 'hooks/useHttpRequest';
import { getAvatarUrl } from 'utils/file';
import { MIN_PAGE_SIZE } from 'utils/constants';

import { DEFAULT_SEARCH_PARAMS } from './consts';
// import { useFeatures } from 'providers/FeatureToggles';

// import {
//   ManageTestOrgUserDialog,
//   CreateTestOrgDialog,
//   ViewTestOrgIdDialog,
// } from './TestOrgDialogs';
// import { getLoginTestOrgURL } from './utils';
import * as api from './api';
import CreateApp from './CreateApp';
import EmptyPlaceholder, { ErrorType } from './EmptyPlaceholder';

import AppSetupEntry from '../apps-wizard/pages/AppSetupEntry';
import { WizardEntrances } from '../apps-wizard/types.d';

import styles from './AppsList.module.less';
import { useLoop } from 'hooks/useLoop';

interface SearchParamsProps {
  keyword?: string;
  page?: number;
  pageSize?: number;
}

enum SearchParamKeys {
  keyword = 'keyword',
  page = 'page',
  pageSize = 'pageSize',
}

function parseAppRawData(app: AppRaw): AppData {
  return {
    key: app.app_id,
    appName: {
      avatar: getAvatarUrl(app.icon_src),
      name: app.name,
    },
    appStatus: {
      status: app.app_status,
      text: getAppStatus(app.app_status),
    },
    // latestReleaseTime: dayjs(app.is_public),
    latestReleaseTime: app?.latest_release_time
      ? dayjs(app.latest_release_time * 1000).format('D MMM,YYYY HH:mm')
      : '-',
    createTime: app?.created_time
      ? dayjs(app.created_time * 1000).format('D MMM,YYYY HH:mm')
      : '-',
    distribution: getAppDistribution(app.is_public),
  };
}

const AppsList: React.FC = () => {
  const {
    activeOrganization,
    // testOrganization,
    // isFetchingTestOrganization,
  } = useContext(ActiveOrganizationContext);
  const companyId = activeOrganization.id;
  const { organizations } = useContext(OrganizationsContext);
  const { request } = useHttpRequest();
  const history = useHistory();
  const push = usePushToast();
  const location = useLocation();
  const [isCreateAppModalOpen, setCreateAppModalOpen] = useState(false);
  // const [isTestOrgModalOpen, setIsTestOrgModalOpen] = useState(false);
  // const [isTestOrgIdModalOpen, setIsTestOrgIdModalOpen] = useState(false);
  const [data, setData] = useState([] as AppData[]);
  const [loading, setLoading] = useState(true);
  const [errorType, setErrorType] = useState(ErrorType.empty);
  const [paginator, setPaginator] = useState({} as Paginator);
  const [searchParams, setSearchParams] = useState<SearchParamsProps>(
    DEFAULT_SEARCH_PARAMS
  );
  const { url } = useRouteMatch();

  const queryParams: SearchParamsProps = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const setQueryAndLocal = useCallback(
    (data: SearchParamsProps) => {
      const { page, pageSize = DEFAULT_SEARCH_PARAMS.pageSize, keyword } = data;
      const params = { page, keyword };
      history.push({
        pathname: location.pathname,
        search: qs.stringify(params),
      });
      localStorage.setItem('pageSize', `${pageSize}`);
    },
    // eslint-disable-next-line
    [companyId]
  );

  const changeSearch = useCallback(
    (key: SearchParamKeys | PaginationKey, value: string | number): void => {
      const params: SearchParamsProps = { ...searchParams, [key]: value };
      // reset page
      if (key === SearchParamKeys.keyword || key === SearchParamKeys.pageSize) {
        params.page = DEFAULT_SEARCH_PARAMS.page;
      }
      setQueryAndLocal(params);
      setSearchParams(params);
    },
    // eslint-disable-next-line
    [companyId, searchParams.keyword, searchParams.page, searchParams.pageSize]
  );

  const searchRequest = useCallback(
    async (companyId, data) => {
      const params = {
        companyId,
        ...data,
        keyword: data.keyword?.trim(),
      };
      try {
        setLoading(true);
        const res: AppListRaw = await request(api.getApps(params));
        setLoading(false);
        if (!res) {
          setErrorType(ErrorType.requestError);
        } else {
          setErrorType(ErrorType.empty);
        }
        const parsed = res?.apps?.map(parseAppRawData) || [];
        setData(parsed);
        setPaginator(res?.paginator || {});
      } catch (error) {
        console.error('app list request error:', error);
        setLoading(false);
      }
    },
    [request]
  );

  const debouncedSearch = useCallback(
    debounce(searchRequest, 300, { leading: false }),
    []
  );

  useLoop();

  useEffect(() => {
    const pageSize =
      Number(localStorage.getItem('pageSize')) ||
      DEFAULT_SEARCH_PARAMS.pageSize;
    const initParams = {
      page: Number(queryParams.page) || DEFAULT_SEARCH_PARAMS.page,
      pageSize,
      keyword: queryParams?.keyword || DEFAULT_SEARCH_PARAMS.keyword,
    };
    setSearchParams(initParams);
    // eslint-disable-next-line
  }, [location, history, organizations]);

  const columns = useMemo(
    () => [
      {
        title: locale('app_list_header_app'),
        dataIndex: 'appName',
        key: 'appName',
        render: ({ avatar, name }: { avatar: string; name: string }) => (
          <div className={styles.appName}>
            <img className={styles.avatar} src={avatar} alt="" />
            <div className={styles.name}>{name}</div>
          </div>
        ),
      },
      {
        title: locale('app_list_header_app_status'),
        dataIndex: 'appStatus',
        key: 'appStatus',
        render: ({ status, text }: { status: AppStatus; text: string }) => {
          const tagPropsMap = {
            [AppStatus.NotReleased]: { color: '#1B92F5' },
            [AppStatus.Online]: { color: '#16C42A' },
            [AppStatus.Maintenance]: { color: '#F5AC1B' },
            [AppStatus.Offline]: { color: '#5E6776' },
          };
          const props = tagPropsMap[status] || {};
          return <Tag {...props}>{text}</Tag>;
        },
      },
      {
        title: locale('app_list_app_last_release_time'),
        dataIndex: 'latestReleaseTime',
        key: 'latestReleaseTime',
        render: (latestReleaseTime: string) => <div>{latestReleaseTime}</div>,
      },
      {
        title: locale('app_list_app_create_time'),
        dataIndex: 'createTime',
        key: 'createTime',
        render: (createTime: string) => <div>{createTime}</div>,
      },
    ],
    // eslint-disable-next-line
    [searchParams.keyword]
  );

  // const [canSeeCreateTestOrgToggle] = useFeatures([
  //   FEATURE_TOGGLE.CAN_SEE_CREATE_TEST_ORG_BUTTON,
  // ]);

  // const handleLoginToTestOrg = useCallback(async () => {
  // const response = await request(api.getSSOToken());
  //   if (response?.token) {
  //     const { token } = response;
  //     window.open(getLoginTestOrgURL(token, testOrganization.id), '_blank');
  //   }
  // }, [request, testOrganization.id]);

  useEffect(() => {
    (async () => {
      if (companyId) {
        setLoading(true);
        debouncedSearch(companyId, searchParams);
      } else {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [
    companyId,
    request,
    searchParams.keyword,
    searchParams.page,
    searchParams.pageSize,
  ]);

  // // todo: loading status
  // if (loading && !data.length) {
  //   // if there is no data yet, show loading if is loading
  //   return <Spinner />;
  // }

  const CreateButton: React.FC = () => (
    <Button
      disabled={!activeOrganization?.id}
      className={styles['create-button']}
      // onClick={() => setCreateAppModalOpen(true)}
      onClick={(): void => {
        history.push(`${location.pathname}/wizard/entry-page`);
      }}
    >
      {locale('app_list_create_app')}
    </Button>
  );

  const RetryButton: React.FC = () => (
    <Button
      className={styles['create-button']}
      onClick={() => {
        debouncedSearch(companyId, searchParams);
      }}
    >
      {locale('action_retry')}
    </Button>
  );

  // const CreateTestOrgButton: React.FC = () => {
  //   const canSeeCreateTestOrg = !!Number(canSeeCreateTestOrgToggle);
  //   return activeOrganization.is_sea || !canSeeCreateTestOrg ? null : (
  //     <div className={styles['create-test-org-button']}>
  //       {!testOrganization || !testOrganization.id ? (
  //         <Button
  //           variant={ButtonVariants.Outlined}
  //           onClick={() => setIsTestOrgModalOpen(true)}
  //         >
  //           {locale('app_list_create_test_org')}
  //         </Button>
  //       ) : (
  //         <Menu
  //           placement={Placements.BottomStart}
  //           button={
  //             <Button
  //               variant={ButtonVariants.Outlined}
  //               rightIcon={<DropdownIcon />}
  //             >
  //               {locale('app_list_test_org')}
  //             </Button>
  //           }
  //         >
  //           <MenuItem onClick={handleLoginToTestOrg}>
  //             {locale('app_list_login_to_test_org')}
  //           </MenuItem>
  //           <MenuItem onClick={() => setIsTestOrgModalOpen(true)}>
  //             {locale('app_list_manage_user')}
  //           </MenuItem>
  //           <MenuItem onClick={() => setIsTestOrgIdModalOpen(true)}>
  //             {locale('app_list_view_test_org_id')}
  //           </MenuItem>
  //         </Menu>
  //       )}
  //     </div>
  //   );
  // };

  const showCreateAppEntry =
    (data.length === 0 && !loading && !searchParams.keyword) || false;

  return (
    <Layout className={styles.layout}>
      <div className={styles.container}>
        <Main className={styles.main}>
          {showCreateAppEntry ? (
            <AppSetupEntry entranceType={WizardEntrances.APP_LIST} />
          ) : (
            <>
              <div className={styles.topBar}>
                <div className={styles.title}>{locale('app_list_title')}</div>
                {data.length ? (
                  <div>
                    {/* <CreateTestOrgButton /> */}
                    <CreateButton />
                  </div>
                ) : null}
              </div>
              <div className={styles.filterBox}>
                <div className={styles.searchBox}>
                  <TextField
                    className={styles.searchField}
                    placeholder={locale('search_input_placeholder')}
                    leftElement={<SearchIcon className={styles.searchIcon} />}
                    rightElement={<TextFieldClear />}
                    value={searchParams.keyword}
                    onChange={(e) => {
                      if (e?.target?.value?.length > 50) {
                        return;
                      }
                      changeSearch(
                        SearchParamKeys.keyword,
                        e?.target?.value || ''
                      );
                    }}
                  />
                </div>
              </div>
              <Table
                className={styles.appList}
                dataSource={data}
                columns={columns}
                loading={loading}
                pagination={false}
                locale={{
                  emptyText: (
                    <EmptyPlaceholder
                      errorType={errorType}
                      slot={
                        errorType === ErrorType.empty ? (
                          <CreateButton />
                        ) : (
                          <RetryButton />
                        )
                      }
                    />
                  ),
                }}
                onRow={({ key }) => ({
                  onClick: () =>
                    history.push(`${url}/${key}`, { page: searchParams.page }),
                })}
              />

              {paginator.total_entries > MIN_PAGE_SIZE && (
                <Pagination paginator={paginator} onChange={changeSearch} />
              )}
            </>
          )}
        </Main>
      </div>
      <CreateApp
        isOpen={isCreateAppModalOpen}
        onClose={() => setCreateAppModalOpen(false)}
        onSubmitted={(appId) => {
          setCreateAppModalOpen(false);
          // setSubmitCount(submitCount + 1);
          history.push(`${url}/${appId}`, {
            source: AppDetailPageSourceType.CREATE_APP_SUCCESS,
          });
          push(ToastTypes.Success, locale('app_list_create_app_success'));
        }}
      />
      {
        /* {
        testOrganization?.id ? (
          <>
            <ManageTestOrgUserDialog
              testOrg={testOrganization}
              isOpen={isTestOrgModalOpen}
              onClose={() => setIsTestOrgModalOpen(false)}
            />
            <ViewTestOrgIdDialog
              isOpen={isTestOrgIdModalOpen}
              onClose={() => setIsTestOrgIdModalOpen(false)}
              testOrgId={testOrganization.display_id || ''}
            />
          </>
        ) : null */
        // <CreateTestOrgDialog
        //   isOpen={isTestOrgModalOpen}
        //   onClose={() => setIsTestOrgModalOpen(false)}
        //   onCreateSuccess={() => fetchTestOrganization()}
        // />
      }
    </Layout>
  );
};

export default AppsList;
