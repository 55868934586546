import React from 'react';
import { length } from 'stringz';
import { useTextFieldContext } from '@seaweb/coral/components/TextField/TextFieldContext';
import classNames from 'classnames';

import styles from './CharCount.module.less';

interface Props {
  outOf: number;
  className?: string;
}

const CharCount: React.FC<Props> = ({ outOf, className = '' }) => {
  const { value } = useTextFieldContext();
  const len = length(value);
  let stateClass = '';
  if (len === 0) stateClass = styles.empty;
  if (len > outOf) stateClass = styles.over;
  return (
    <div className={classNames(styles.charCount, { [className]: className })}>
      <span className={stateClass}>{len}</span>
      <span className={styles.outOf}>/{outOf}</span>
    </div>
  );
};

export default CharCount;
