import locale from 'common/locale';

export const MAX_AVATAR_SIZE = 250;
export const MAX_APP_NAME = 30;
export const MAX_APP_DESCRIPTION = 200;
export const MAX_RELEASE_TITLE = 50;
export const MAX_CHANGE_LOG = 500;
export const MAX_COMMENT_FOR_REVIEW = 500;
export const MAX_BUNDLE_FILE_SIZE = 50;
export const MAX_EVENT_CB_URL = 200;
export const MAX_APP_URL = 200;
// set the search results's size
export const MAX_TITLE_FRAGMENT_SIZE = 50;
export const MIN_CONTENT_FRAGMENT_SIZE = 45;
export const MIN_PAGE = 1;
export const MIN_PER_PAGE = 5;
export const MIN_PAGE_SIZE = 10;
export const DEFAULT_PER_PAGE = 20;
export const MAX_INPUT_LENGTH = 100;
export const SHADOW_BLUR_THRESHOLD = 20;

export const IS_LIVE =
  process.env.BUILD_ENV === 'live' || process.env.BUILD_ENV === 'liveish';

export const APP_LINK_DOMAIN = !IS_LIVE
  ? 'https://link.test.seatalk.io'
  : 'https://link.seatalk.io';

export const ONE_DAY_TIMESTAMP = 86400000;

export const GTAG_ID = IS_LIVE ? 'G-PM5RSVWJXF' : 'G-G6P1MPCYDH';

export const LOOP_ID = IS_LIVE
  ? '9f2fcc69-024f-433a-b461-d905c465d0ee'
  : 'd7f741c6-2f9a-4ae5-9463-f7c6c20da5bf';

export const MIN_ALLOW_EXPAND = 10;

export const APPLY_SCOPE_GUIDE_LINK =
  'https://open.seatalk.io/docs/Apply-for-Service-Scope-and-Data-Scope';

// for apply apps scope & permission
export const SERVICE_EXPLANATION_LINK = {
  text: locale('action_learn_more'),
  href: APPLY_SCOPE_GUIDE_LINK,
};
export const DATA_EXPLANATION_LINK = {
  text: locale('action_learn_more'),
  href: APPLY_SCOPE_GUIDE_LINK,
};
