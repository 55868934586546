import locale from 'common/locale';

import { AppStatus, AppType, AppVersionStatus, HostOption } from './enums';

const appStatusMap = {
  [AppStatus.NotReleased]: locale('app_status_not_released'),
  [AppStatus.Online]: locale('app_status_online'),
  [AppStatus.Maintenance]: locale('app_status_maintenance'),
  [AppStatus.Offline]: locale('app_status_offline'),
};

export function getAppStatus(code: AppStatus) {
  return appStatusMap[code];
}

const appVersionStatusMap = {
  [AppVersionStatus.Draft]: locale('app_version_status_draft'),
  [AppVersionStatus.UnderReview]: locale('app_version_status_under_review'),
  [AppVersionStatus.Rejected]: locale('app_version_status_rejected'),
  [AppVersionStatus.Released]: locale('app_version_status_released'),
};

export function getAppVersionStatus(code: AppVersionStatus) {
  return appVersionStatusMap[code];
}

const appDistributionMap = {
  1: locale('app_info_distribution_public'),
  0: locale('app_info_distribution_private'),
};

export function getAppDistribution(code: boolean) {
  return code ? appDistributionMap[1] : appDistributionMap[0];
}

const appCapabilityMap = {
  [AppType.DISABLED]: locale('app_info_disabled'),
  [AppType.BOT]: locale('app_info_capability_bot'),
  [AppType.RN]: locale('app_info_capability_rn_app'),
  [AppType.WEB]: locale('app_info_capability_web_app'),
};

export function getAppCapability(code: AppType) {
  return appCapabilityMap[code];
}

const hostOptionMap = {
  [HostOption.HOSTED_BY_SEATALK]: locale('app_info_host_option_seatalk'),
  [HostOption.SELF_HOST]: locale('app_info_host_option_self_host'),
  [HostOption.NO_HOST]: '',
};

export function getHostOption(code: HostOption) {
  return hostOptionMap[code];
}

export const getImageType = (base64Str: string): string => {
  const typeStr = base64Str?.match(/image\/.+;/)?.[0] || '';
  return typeStr.replace(';', '');
};
