import React, { MouseEventHandler } from 'react';
import ChevronUpIcon from '@seaweb/coral/icons/ChevronUp';

export enum CHEVRON_DIRECTION {
  UP = 'up',
  RIGHT = 'right',
  DOWN = 'down',
  LEFT = 'left',
}

interface ChevronProps {
  color?: string;
  direction?: CHEVRON_DIRECTION;
  size?: number;
  onClick?: MouseEventHandler<SVGSVGElement>;
}

const Chevron: React.FC<ChevronProps> = ({
  color,
  direction,
  size,
  onClick,
}) => {
  const CHEVRON_ROTATION_DEGREE = {
    [CHEVRON_DIRECTION.UP]: 0,
    [CHEVRON_DIRECTION.RIGHT]: 90,
    [CHEVRON_DIRECTION.DOWN]: 180,
    [CHEVRON_DIRECTION.LEFT]: -90,
  };

  return (
    <ChevronUpIcon
      color={color}
      size={size || undefined}
      style={{
        transform: `rotate(${
          CHEVRON_ROTATION_DEGREE[direction || CHEVRON_DIRECTION.UP]
        }deg)`,
      }}
      onClick={onClick}
    />
  );
};

export default Chevron;
