import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Space } from 'antd';

import locale from 'common/locale';

import { CapabilityType, WizardEntrances } from '../../types.d';
import ContentBox from '../../components/EntryContentBox';
import Chevron from 'components/chevron';

import styles from './AppSetupEntry.module.less';
import { CHEVRON_DIRECTION } from 'components/chevron/Chevron';

interface Props {
  entranceType: WizardEntrances;
}

const AppSetupEntry: React.FC<Props> = ({ entranceType }) => {
  const history = useHistory();
  const { url } = useRouteMatch('/company/:companyId/apps') as { url: string };
  // 判断来源，选择是否展示返回icon
  const isShow = entranceType === WizardEntrances.ROUTER;
  const handleGoBack = (): void => {
    history.push('/company');
  };

  return (
    <div className={styles.container}>
      {isShow && (
        <div className={styles.header}>
          <div className={styles.backIcon}>
            <Chevron
              direction={CHEVRON_DIRECTION.LEFT}
              onClick={handleGoBack}
            />
            {/* <span>{locale('action_back')}</span> */}
          </div>
          <span>{locale('app_wizard_entry_action_back')}</span>
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.textContainer}>
          <div className={styles.title}>
            {isShow
              ? locale('app_wizard_entry_create_new_app')
              : locale('app_wizard_entry_create_first_app')}
          </div>

          <div className={styles.description}>
            {locale('app_wizard_entry_create_description')}&nbsp;
            <Link target="_blank" to="/docs/understand-app-capabilities">
              {locale('app_wizard_entry_create_description_learn_more')}
            </Link>
          </div>
        </div>

        <div className={styles.boxContainer}>
          <Space align="center">
            <ContentBox
              title={locale('app_wizard_entry_bot_service_title')}
              description={locale(
                'app_info_app_capability_bot_service_description'
              )}
              handleNavigation={(): void =>
                history.push(
                  `${url}/wizard/setup-app?type=${CapabilityType.BOT_SERVICE}`
                )
              }
            />

            <ContentBox
              title={locale('app_wizard_entry_workspace_app_title')}
              description={locale('app_info_app_capability_app_description')}
              handleNavigation={(): void =>
                history.push(
                  `${url}/wizard/setup-app?type=${CapabilityType.WORKSPACE_APP}`
                )
              }
            />

            <ContentBox
              title={locale('app_wizard_entry_login_with_seatalk_title')}
              description={locale(
                'app_info_app_capability_login_with_seatalk_description'
              )}
              handleNavigation={(): void =>
                history.push(
                  `${url}/wizard/setup-app?type=${CapabilityType.LOGIN_WITH_SEATALK}`
                )
              }
            />

            <ContentBox
              title={locale('app_wizard_entry_more_title')}
              description={locale('app_wizard_entry_more_description')}
            />
          </Space>
        </div>
      </div>
    </div>
  );
};

export default AppSetupEntry;
