import React, { useContext, useEffect, useState } from 'react';
// import { useHttpRequest } from 'hooks/useHttpRequest';
// import { getTestOrg } from 'pages/apps-tabs/apps-list/api';
import { useRouteMatch } from 'react-router-dom';

import { OrganizationsContext } from './OrganizationsProvider';
// import { sortByKey } from 'utils/response';

interface ActiveOrganizationContextInterface {
  activeOrganization: Organization;
  testOrganization?: Organization;
  setActiveOrganization: (v: Organization) => void;
  fetchTestOrganization?: () => void;
  isFetchingTestOrganization?: boolean;
}

export const ActiveOrganizationContext =
  React.createContext<ActiveOrganizationContextInterface>(
    {} as ActiveOrganizationContextInterface
  );

const ActiveOrganizationProvider: React.FC = ({ children }) => {
  const matchRoute = useRouteMatch('/company/:companyId') as any;
  const { organizations } = useContext(OrganizationsContext);
  // const { request } = useHttpRequest();
  // const [isFetchingTestOrganization, setIsFetchingTestOrganization] =
  //   useState(false);
  const [activeOrganization, setActiveOrganization] = useState<Organization>(
    {} as Organization
  );

  // const [testOrganization, setTestOrganization] = useState<Organization>(
  //   {} as Organization
  // );

  // const fetchTestOrganization = useCallback(async () => {
  //   const hasPermission = activeOrganization?.roles?.length;
  //   if (!activeOrganization.id || !hasPermission) {
  //     return;
  //   }
  //   setIsFetchingTestOrganization(true);
  //   const response = await request(getTestOrg(activeOrganization.id));
  //   const testCompany: Organization = response?.test_company;
  //   if (response && testCompany) {
  //     setTestOrganization({
  //       ...testCompany,
  //       developers: sortByKey(testCompany.developers || [], 'name'),
  //     });
  //   }
  //   setIsFetchingTestOrganization(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeOrganization.id, request, setTestOrganization]);

  // fetch test org everytime active org is changed.
  // useEffect(() => {
  //   fetchTestOrganization();
  // }, [fetchTestOrganization]);

  // logic to set activeOrganization
  useEffect(() => {
    if (organizations.length === 0) {
      return;
    }
    // retrieve from localStorage
    let organizationLocal: Organization;
    try {
      organizationLocal = JSON.parse(
        localStorage.getItem('organization') || ''
      ) as Organization;
    } catch (err) {
      organizationLocal = {} as Organization;
    }
    let activeOrg = null;

    if (
      matchRoute === null ||
      parseInt(matchRoute.params.companyId, 10) === organizationLocal.id
    ) {
      // if there is not companyId in url, verify and decide that can use organizationLocal
      // activeOrg = organizations[0];
      if (
        !organizationLocal.id ||
        !organizations.find(({ id }) => id === organizationLocal.id)
      ) {
        // if there is no organizationLocal or it has been deleted (cant find from the list from server), set the first one
        activeOrg = organizations[0];
      } else {
        // find the org with organizationLocal.id then save it to localStorage
        activeOrg = organizations.find(({ id }) => id === organizationLocal.id);
      }
    } else {
      const companyIdOnURL = parseInt(matchRoute.params.companyId, 10);
      activeOrg = organizations.find((e) => e.id === companyIdOnURL);
    }
    if (activeOrg) {
      setActiveOrganization(activeOrg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchRoute, organizations, setActiveOrganization]);

  useEffect(() => {
    if (activeOrganization.id) {
      localStorage.setItem('organization', JSON.stringify(activeOrganization));
    }
  }, [activeOrganization]);

  return (
    <ActiveOrganizationContext.Provider
      value={{
        activeOrganization,
        setActiveOrganization,
        // testOrganization,
        // fetchTestOrganization,
        // isFetchingTestOrganization,
      }}
    >
      {children}
    </ActiveOrganizationContext.Provider>
  );
};

export default ActiveOrganizationProvider;
