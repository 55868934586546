import axios from 'axios';
import React, { FC, memo, useEffect, useState, CSSProperties } from 'react';
import * as DOMPurify from 'dompurify';

import './style.less';
import { IS_LIVE } from 'utils/constants';

type BannerConfig = {
  show: {
    sop: boolean;
  };
  content: string;
};

type BannerState = {
  content?: string;
  show?: boolean;
};

const purifyCfg: DOMPurify.Config = {
  ALLOWED_TAGS: ['p', 'strong'],
  ALLOWED_ATTR: ['class'],
  FORBID_ATTR: ['onclick', 'onerror', 'onload'],
};

type AnnouncementBannerProps = {
  className?: string;
  style?: CSSProperties;
};

const configURL = {
  nonLive:
    'https://deo.shopeemobile.com/shopee/shopee-seatalkcdn-test-sg/seatalk-banner-config.json',
  live: 'https://deo.shopeemobile.com/shopee/shopee-seatalkcdn-live-sg/seatalk-banner-config.json',
};

const AnnouncementBanner: FC<AnnouncementBannerProps> = (props) => {
  const { className = '', style: styleProps = {} } = props;

  const [banner, setBanner] = useState<BannerState>({});

  useEffect(() => {
    axios
      .get<BannerConfig>(IS_LIVE ? configURL.live : configURL.nonLive)
      .then(({ data }) => {
        if (data) {
          setBanner({
            show: data.show.sop,
            content: DOMPurify.sanitize(
              (data.content || '').trim(),
              purifyCfg
            ) as string,
          });
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('[notification] Error get notification');
      });
  }, []);

  if (!banner.show || !banner.content) {
    return null;
  }

  return (
    <div
      id="announcement-banner"
      className={className}
      dangerouslySetInnerHTML={{
        __html: banner.content,
      }}
      style={styleProps}
    />
  );
};

export default memo(AnnouncementBanner);
