/** todo: permission类型重复，待后续统一 */
export interface PermissionItemV2 {
  permission_key: string;
  permission_name: string;
  remark: string;
  status: number;
  limited_by?: number;
  doc_link?: string;
  approval_required: boolean;
}

export interface PermissionGroup {
  group_key: string;
  group_name: string;
  permissions: PermissionItemV2[];
}

export interface ApiPermissionInfo {
  permission_groups: PermissionGroup[];
}

export enum CapabilityType {
  UNSPECIFIED = 0,
  BOT_SERVICE = 1,
  WORKSPACE_APP = 2,
  LOGIN_WITH_SEATALK = 3,
}

export enum WizardStep {
  BASIC_INFO = 1,
  API_PERMISSION = 2,
  SCOPE = 3,
}

export interface AppWizardCapability {
  capability_type: CapabilityType;
  api_permissions: ApiPermissionInfo;
  service_scope_departments: AllowedDepartmentInfo[];
}

export interface AppWizardIndicator {
  value: number;
  desc: string;
}

export interface AppsRequestParams {
  companyId: number;
  keyword?: string;
  page?: number;
  pageSize?: number;
}

export interface Avatar {
  code?: number;
  icon_name: string;
  uss_icon: string;
  icon_src: string;
}

export interface BasicAppInfoInputs {
  name: string;
  avatar: Avatar;
  description: string;
}

export interface CreateAppRequestParams extends BasicAppInfoInputs {
  companyId: number;
  capabilityType?: CapabilityType;
  serviceScopeDepartment?: number[];
}

export interface AppCapabilitiesRequest {
  companyId: number;
}

export interface AppCapabilitiesResponse {
  code: number;
  capabilities: AppWizardCapability[];
}

export enum WizardEntrances {
  ROUTER,
  APP_LIST,
}
