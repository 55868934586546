import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariants } from '@seaweb/coral/components/Button';
import { ToastTypes } from '@seaweb/coral/components/Toast';
import { Cookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { PusherPositions } from '@seaweb/coral/components/Pusher';

import locale from 'common/locale';
import { ReactComponent as GoogleIcon } from 'assets/images/icon_google.svg';
import usePushToast from 'hooks/usePushToast';
import { sendTrackingEvent, TRACK_GOOGLE_LOGIN_ATTEMPT } from 'utils/tracking';

const GoogleButton = styled(Button)`
  color: #202325;
  padding: 8px 16px;
  height: 40px;
  border: 1px solid #e1e1e7;
`;

interface IGoogleLoginProps {
  state: string;
  className?: string;
}

const GoogleLogin = ({ state, className }: IGoogleLoginProps) => {
  const [scriptLoading, setScriptLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const codeClientRef = useRef<{ requestCode: () => void } | null>(null);

  const { search } = useLocation();

  const pushToast = usePushToast();

  useEffect(() => {
    if (state) {
      const el = document.createElement('script');

      el.src = 'https://accounts.google.com/gsi/client';
      el.onload = () => {
        codeClientRef.current = window.google.accounts.oauth2.initCodeClient({
          client_id: process.env.REACT_APP_GOOGLE_APP_ID || '',
          redirect_uri: `${window.location.origin}/login`,
          scope: 'https://www.googleapis.com/auth/userinfo.email',
          ux_mode: 'redirect',
          state,
        });

        setScriptLoading(false);
      };
      el.onerror = () => {
        setErrorMessage(locale('login_google_script_error'));
        setScriptLoading(false);
      };

      document.body.append(el);
    }
  }, [state]);

  const onClick = () => {
    if (errorMessage) {
      pushToast(
        ToastTypes.Fail,
        errorMessage,
        false,
        PusherPositions.MiddleCenter
      );
      return;
    }

    if (codeClientRef.current) {
      const cookies = new Cookies();
      const searchParams = new URLSearchParams(search);

      if (searchParams.get('destination')) {
        cookies.set('destination', searchParams.get('destination'), {
          path: '/',
        });
      } else {
        cookies.remove('destination');
      }

      sendTrackingEvent({ name: TRACK_GOOGLE_LOGIN_ATTEMPT });
      codeClientRef.current.requestCode();
    }
  };

  return (
    <GoogleButton
      className={className}
      variant={ButtonVariants.Outlined}
      leftIcon={<GoogleIcon />}
      disabled={scriptLoading}
      onClick={onClick}
    >
      {locale('login_google_btn_label')}
    </GoogleButton>
  );
};

export default GoogleLogin;
