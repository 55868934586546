/**
 * https://github.com/sindresorhus/ky
 */
import ky from 'ky';

// export default ky.create({ prefixUrl: '/api' });

export const server = `${window.location.origin}/api/`;

export async function request(
  url = '',
  method = 'post',
  data = {} as Record<string, any>
) {
  if (method === 'get') {
    const cleanedData = Object.keys(data).reduce(
      (acc, key) =>
        data[key] === undefined ? acc : { ...acc, [key]: data[key] },
      {}
    );
    return ky.get(server + url, {
      searchParams: new URLSearchParams(cleanedData),
      method: 'get',
    });
  } else if (method === 'post') {
    const newUrl = data.company_id
      ? `${url}?company_id=${data.company_id}`
      : url;
    return ky.post(server + newUrl, { json: data, method: 'post' });
  }
}

// export async function getPostUrl({
//   redirectUri,
// }: { redirectUri?: string } = {}) {
//   return request('auth//v2/login', 'get', { redirect_uri: redirectUri });
// }

declare interface ErrorItem {
  code: number | string;
  desc: string;
}

export const ResponseCode: Record<string, ErrorItem> = {
  SUCCESS: {
    code: 0,
    desc: 'Successfully',
  },
  INVALID_ARGUMENT: {
    code: 3,
    desc: 'Invalid Argument',
  },
  NOT_FOUND: {
    code: 5,
    desc: 'Not Found',
  },
  ALREADY_EXISTS: {
    code: 6,
    desc: 'Already Exists',
  },
  PERMISSION_DENIED: {
    code: 7,
    desc: 'Permission Denied',
  },
  RESOURCE_EXHAUSTED: {
    code: 8,
    desc: 'Resource Exhausted',
  },
  SYS: {
    code: 3000000,
    desc: 'general system error',
  },
  UNAUTHORIZED: {
    code: 3000001,
    desc: 'Unauthorized',
  },
  DEPENDENCE_UNAUTHORIZED: {
    code: 3000002,
    desc: 'Dependence Unauthorized',
  },
  APP_NAME_DUPLICATE: {
    code: 3000010,
    desc: 'App Name Duplicate',
  },
  OUT_OF_SERVICE_SCOPE: {
    code: 3000011,
    desc: 'Out Of Service Scope',
  },
  APP_REQUEST_NOT_PENDING: {
    code: 3001001,
    desc: 'App Request Not Pending',
  },
  DEFAULT: {
    code: 'DEFAULT',
    desc: 'App Request Not Pending',
  },
};
