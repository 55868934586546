import { init } from '@seaweb/loop';
import { CurrentUserContext } from 'providers';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LOOP_ID } from 'utils/constants';

export function useLoop() {
  const { currentUser } = useContext(CurrentUserContext);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (currentUser && currentUser.email) {
      const { cancel } = init(LOOP_ID, {
        getEmail: () => currentUser.email,
      });
      if (isPathExist(pathname, excludedPath)) {
        cancel();
      }
      return cancel;
    }
    const { cancel } = init(LOOP_ID);
    if (isPathExist(pathname, excludedPath)) {
      cancel();
    }
    return cancel;
  }, [currentUser?.email, pathname]);
}

const excludedPath = [
  /capability\/release\/new/,
  /capability\/release\/\d+\/edit/,
  /permission\/edit/,
];

function isPathExist(path: string, arr: RegExp[]) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].test(path)) {
      return true;
    }
  }
  return false;
}
