import { Cookies } from 'react-cookie';

import { MAX_APP_URL } from 'utils/constants';
import locale from 'common/locale';
import { getQueryByName } from 'utils/helpers';

interface FormData {
  [key: string]: any;
}

export function submitForm(
  url: string,
  data: FormData = {},
  method = 'POST'
): void {
  // setCookie
  const currentUrl = window.location.href;
  const destination = getQueryByName('destination', currentUrl);
  if (destination) {
    const cookie = new Cookies();
    cookie.set('destination', destination, { path: '/' });
  }

  // create form element
  const form = document.createElement('form');
  form.method = method;
  form.action = url;

  // create input elements
  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = data[key];
    form.appendChild(input);
  }

  // submit the form
  document.body.appendChild(form);
  form.submit();
}

export function checkURL(str: string, requiredHttps = false): boolean {
  try {
    const url = new URL(str);
    if (
      (requiredHttps && url.protocol === 'https:') ||
      (!requiredHttps &&
        (url.protocol === 'http:' || url.protocol === 'https:'))
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export function isValidHTTPSURL(url: string) {
  return checkURL(url, true);
}

export const validateAppURL = {
  required: false,
  validate: (url: string) => {
    if (!url || url.length === 0) {
      return true;
    }
    const isValidURL = checkURL(url);
    if (isValidURL && url.length <= MAX_APP_URL) {
      return true;
    }
    return !isValidURL
      ? locale('app_list_create_app_app_url_invalid')
      : locale('url_error_max_length', {
          type: 'App URL',
          len: MAX_APP_URL,
        });
  },
};
