import { trim } from 'lodash';

export function getCookie(name: string) {
  const cookie: Record<string, string> = {};
  document.cookie.split(';').forEach((el) => {
    const [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });
  return cookie[name];
}

export function setCopyText(
  text: string,
  callback?: (text: string) => void | void
): void {
  const element: HTMLInputElement = document.createElement('input');
  const copyElementTemporaryId = `copy_element_temporary_id${Date.now()}`;
  element.setAttribute('id', copyElementTemporaryId);
  element.value = text;
  document.body.appendChild(element);
  const tempElement: HTMLInputElement = document.getElementById(
    copyElementTemporaryId
  ) as HTMLInputElement;
  tempElement.select();
  document.execCommand('copy');
  tempElement.remove();
  if (callback) {
    callback(text);
  }
}

export function getQueryByName(name: string, url: string): string {
  const reg = new RegExp(`[?&]${name}=([^&]*)`);
  const matches: Array<string> = url.match(reg) || [];
  return decodeURIComponent(matches[1] || '');
}

export function checkJson(str: string): Record<string, any> | boolean {
  try {
    const obj = JSON.parse(str);
    if (typeof obj === 'object' && obj) {
      return obj;
    }
  } catch (e) {
    return false;
  }

  return false;
}

export const transformContentDigest = (content_digest: string): string => {
  const regex = /[.!?]$/;
  let content = trim(content_digest);
  // test the end of sentence
  if (!regex.test(content)) {
    content = `${content}...`;
  }
  return content;
};

/**
 * In lodash's deepEqual, two arrays are are considered not deeply equal
 * if the order of both arrays elements are not same.
 * This function disregard elements order.
 * @param {any[]} arrA
 * @param {any[]} arrB
 * @returns boolean
 */
export const arrayDeepEqual = (arrA: any[], arrB: any[]): boolean => {
  const isArrA = Array.isArray(arrA);
  const isArrB = Array.isArray(arrB);
  if (!(isArrA && isArrB)) return false;

  if (arrA.length !== arrB.length) return false;

  const arrAMap: Record<string, true> = {};
  arrA.forEach((a) => {
    arrAMap[a] = true;
  });

  for (let b = 0, len = arrB.length; b < len; b++) {
    if (!arrAMap[arrB[b]]) return false;
  }

  return true;
};
