import React, { useState } from 'react';
import { Upload } from 'antd';
import { ToastTypes } from '@seaweb/coral/components/Toast';

import locale from 'common/locale';
import AvatarImg from 'components/avatar-img';
import AvatarCrop from 'components/avatar-crop';
import usePushToast from 'hooks/usePushToast';
import { useHttpRequest } from 'hooks/useHttpRequest';

import { uploadIcon } from './apis';

import styles from './AvatarUpload.module.less';

interface Avatar {
  code?: number;
  icon_name: string;
  uss_icon?: string;
  icon_src?: string;
}

interface Props {
  action: string;
  accept?: string;
  maxSize?: number;
  value?: Avatar;
  onChange?: (val: Avatar) => void;
  name: string;
  companyId: number;
  setError: (name: string, error: { type: string; message: string }) => void;
  clearErrors: (name: string) => void;
  onBlur?: () => void;
}

const defaultProps: Partial<Props> = {
  accept: 'image/jpeg,image/png,image/x-png',
};

const AvatarUpload: React.FC<Props> = ({
  action,
  accept,
  maxSize,
  value,
  onChange,
  onBlur,
  name,
  setError,
  clearErrors,
  companyId,
}) => {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const push = usePushToast();
  const { request } = useHttpRequest();
  const onUpload = async (blob: Blob): Promise<void> => {
    if (!file) {
      return;
    }
    const newFile = new File(
      [blob],
      `${file.name.substring(0, file.name.lastIndexOf('.'))}.jpeg`,
      { type: 'image/jpeg' }
    );
    const params = {
      companyId,
      file: newFile,
    };
    setLoading(true);
    request(uploadIcon(params))
      .then((res) => {
        if (res.code === 0) {
          clearErrors(name);
          if (onChange) {
            onChange(res);
            if (onBlur) {
              onBlur();
            }
          }
          setFile(undefined);
        } else {
          push(ToastTypes.Fail, locale('app_icon_upload_error'));
        }
      })
      .catch(() => {
        push(ToastTypes.Fail, locale('app_icon_upload_error'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles['avatar-editable']}>
      <AvatarImg size={80} src={value?.icon_src || ''} />
      <Upload
        className={styles.edit}
        showUploadList={false}
        accept={accept}
        beforeUpload={(file): boolean => {
          setFile(file);
          return false;
        }}
      >
        {value ? (
          <span>{locale('action_edit')}</span>
        ) : (
          <span>{locale('action_upload')}</span>
        )}
      </Upload>
      {file && (
        <AvatarCrop
          file={file}
          loading={loading}
          onUpload={onUpload}
          onCancel={(): void => {
            setFile(undefined);
          }}
        />
      )}
    </div>
  );
};

AvatarUpload.defaultProps = defaultProps;

export default AvatarUpload;
