import React from 'react';

// import Field, { LabelPositions } from '@seaweb/coral/components/Field';
// import Radio, { RadioGroup } from '@seaweb/coral/components/Radio';
// import styles from './BasicAppInfoForm.module.less';
// import locale from 'common/locale';
import BasicAppInfoForm, {
  AppInfoFormProps,
  BasicAppInfoInputs,
} from './BasicAppInfoForm';
// import { Controller } from 'react-hook-form';

export interface CreateAppFormInputs extends BasicAppInfoInputs {
  // distribution: boolean;
  capability: number;
}

const CreateAppForm: React.FC<AppInfoFormProps> = ({
  formHook,
  activeOrganization,
  fieldWidthPercent,
  labelWidthPercent,
}) => (
  // const fieldContainerProps = {
  //   style: { width: `${fieldWidthPercent ?? 40}%` },
  // };

  // const labelContainerProps = {
  //   style: {
  //     width: `${labelWidthPercent ?? 30}%`,
  //     flexBasis: `${labelWidthPercent ?? 30}%`,
  //   },
  // };

  // const { is_isv } = activeOrganization;
  // const { control, errors } = formHook;
  <>
    <BasicAppInfoForm
      labelWidthPercent={labelWidthPercent}
      fieldWidthPercent={fieldWidthPercent}
      activeOrganization={activeOrganization}
      formHook={formHook}
    />
    {/* {is_isv && (
        <>
          <Field
            labelProps={labelContainerProps}
            containerProps={fieldContainerProps}
            required
            label={locale('app_info_property_distribution')}
            labelPosition={LabelPositions.Left}
            invalid={!!errors.distribution}
          >
            <Controller
              render={({ value, onChange, onBlur }) => (
                <RadioGroup
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    onBlur();
                  }}
                  className={styles['radio-group-line']}
                >
                  <Radio value={false}>
                    {locale('app_info_distribution_private')}
                    {' - '}
                    <span>{locale('app_info_distribution_private_desc')}</span>
                  </Radio>
                  <Radio value={true}>
                    {locale('app_info_distribution_public')}
                    {' - '}
                    <span>{locale('app_info_distribution_public_desc')}</span>
                  </Radio>
                </RadioGroup>
              )}
              control={control}
              name="distribution"
            />
          </Field>
        </>
      )} */}
  </>
);
export default CreateAppForm;
