import React from 'react';
import Button from '@seaweb/coral/components/Button';

import locale from 'common/locale';

import styles from './EntryContentBox.module.less';

interface Props {
  title: string;
  description: string;
  handleNavigation?: () => void;
}
const ContentBox: React.FC<Props> = ({
  title,
  description,
  handleNavigation,
}) => (
  <div className={styles.box}>
    <div className={styles.textContainer}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
    {handleNavigation && (
      <Button
        onClick={(): void => handleNavigation()}
        className={styles.button}
      >
        {locale('action_setup')}
      </Button>
    )}
  </div>
);

export default ContentBox;
