import React, { memo, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import locale from 'common/locale';

import styles from './EmptyPlaceholder.module.less';

export enum ErrorType {
  empty = 'empty',
  requestError = 'requestError',
}
export interface LinkConfig {
  text: string;
  callback: () => void;
}

interface Props {
  errorType?: ErrorType;
  slot?: ReactNode;
}

const messageMap: Record<string, string> = {
  empty: locale('app_list_no_app'),
  requestError: locale('app_list_request_error_message'),
};
const tipsMap: Record<string, string> = {
  empty: locale('app_list_no_app_tips'),
  requestError: locale('app_list_request_error_tips'),
};

const EmptyPlaceholder: React.FC<Props> = ({
  errorType = ErrorType.empty,
  slot,
}) => {
  const history = useHistory();

  const logo = `${errorType}Logo`;

  const linkMap: Record<string, LinkConfig> = {
    empty: {
      text: 'Get-Start Documentation',
      callback: () => {
        history.push('/docs/introduction-to-Seatalk-open-platform');
      },
    },
  };

  const linkConfig: LinkConfig = linkMap[errorType] || null;

  return (
    <div className={styles.Container}>
      <div className={styles.box}>
        <div className={styles[logo]} />
        <div className={styles.message}>
          <span>{messageMap[errorType]}</span>
        </div>
        <div className={styles.tips}>
          <span>{tipsMap[errorType]}</span>
          {linkConfig && (
            <div className={styles.link} onClick={linkConfig.callback}>
              {linkConfig.text}
            </div>
          )}
        </div>
        {slot}
      </div>
    </div>
  );
};

export default memo(EmptyPlaceholder);
